.split {
    display: flex;
    height: 100%;

    > .resize-bar {
        position: relative;
        background-color: rgb(232, 240, 234);
        width: 10px;
        cursor: col-resize;
        box-shadow: 0 0 15px rgba(black, .2);
        clip-path: inset(0px -15px 0px 0px);

        &:after {
            position: absolute;
            content: url("/assets/icons/resize_handle.svg");
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(.7);
        }
    }

    > .lhs {
        width: 50%;
    }

    > .rhs {
        flex: 1;
    }
}
