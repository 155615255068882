@import "style-base";

.kpir {
    > [data-component="modal"] {
        > .modal-content {
            width: 97vw;
        }
    }
}

.kpir-list {
    @extend .hideen-scrollbars;

    .modal-content {
        width: 97vw !important;
    }   
}
