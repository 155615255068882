@import "_vars";

.password-label-container {
    position: relative;
}

.password-status {
    background-color: white;
    z-index: 100;
    position: absolute;
    border: 1px solid #CBCBCB;
    bottom: 5.79rem;
    padding: 2rem;
    border-radius: .8rem;
    box-shadow: 1px 1px 12px 4px rgba(0, 0, 0, .04);
    left: 50%;
    transform: translateX(-50%);
    width: 23rem;

    &:after, &:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        z-index: 100;
    }

    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: white;
        border-width: 13px;
        margin-left: -13px;
    }

    &:before {
        border-color: rgba(172, 199, 217, 0);
        border-top-color: #CBCBCB;
        border-width: 14px;
        margin-left: -14px;
    }

    &__header {
        font-size: 1.15rem;
        font-weight: 500;
        margin-bottom: .7rem;
        color: black;
    }

    > .rule {
        color: #E62727;
        padding-left: 2.5rem;
        margin: .2rem 0;
        background-image: url("/assets/icons/red_cross.svg");
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 1.4rem;

        &[data-is-valid="true"] {
            color: #30B561;
            background-image: url("/assets/icons/green_tick.svg");
        }
    }

    > .strength {
        margin-top: 1rem;
        font-size: 1.15rem;

        .progress-bar {
            background: #ECECEC;
            height: .6rem;
            width: 100%;
            padding: 0;
            margin: .6rem 0;
            border-radius: 5px;
        }

        .progress-bar:after {
            content: "";
            display: block;
            width: 50%;
            height: 100%;
            border-radius: 5px;
            transition: width .6s ease;
        }

        &--0 {
            .progress-bar:after {
                background: #E62727;
                width: 0%;
            }
        }

        &--1 {
            .progress-bar:after {
                background: #E62727;
                width: 20%;
            }
        }

        &--2 {
            .progress-bar:after {
                background: #F0AD4E;
                width: 40%;
            }
        }

        &--3 {
            .progress-bar:after {
                background: #5ED489;
                width: 60%;
            }
        }

        &--4 {
            .progress-bar:after {
                background: $green;
                width: 80%;
            }
        }

        &--5 {
            .progress-bar:after {
                background: #30B561;
                width: 100%;
            }
        }

        .password-status__header {
            margin-right: .2rem;
        }
    }
}
