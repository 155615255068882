#modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    transition: opacity .3s;
    opacity: .3;
    z-index: 5000;
    pointer-events: all;

    &[data-is-hidden="true"] {
        pointer-events: none;
        opacity: 0;
    }
}

[data-component="modal"] {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s;
    z-index: 7500;
    max-width: 100%;

    &[data-is-visible="true"] {
        pointer-events: all;
        opacity: 1;
    }

    > .modal-content {
        background-color: white;
        padding: 3.57rem 3.57rem 1.79rem;
        max-height: 98vh;
        display: flex;
        flex-direction: column;

        > .modal-header {
            position: relative;
            font-size: 1.86rem;
            font-weight: 600;
            padding-right: 20px;
            user-select: none;
            letter-spacing: 3px;

            > .cross {
                position: absolute;
                top: -.8rem;
                right: 0;
                font-size: 2rem;
                cursor: pointer;
                transition: opacity .3s;
                opacity: 1;
                font-weight: 300;

                &:hover {
                    opacity: .5;
                }
            }
        }

        > .modal-body {
            position: relative;
            background-color: #F8F7FC;
            overflow: auto;
            margin-top: .9rem;
            display: flex;

            > .table--bank-accounts--add {
                width: 100%;
            }
        }

        > .modal-footer {
            margin-top: 2.14rem;
            text-align: right;
            user-select: none;

            [role="button"] {
                margin-left: 20px;
            }
        }
    }
}
