.labels-breakdown {
    display: flex;

    > .graph {
        flex: 1;
    }

    > .controls {
        margin-top: 10px;
    }
}
