.integrations-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.integrations {
    .integration-form {
        padding: 2.68rem 2.14rem;
    }

    > .controls {
        margin-left: 2.86rem;
        margin-top: 20px;
    }

    > .current {
        margin: 4rem 0 3rem 2.86rem;

        > .integration {
            display: flex;
            align-items: center;
            font-size: 12px;
            margin-bottom: 10px;

            > .system {
                margin-right: 25px;
                font-weight: 500;
            }

            > .name {
                margin-right: 25px;
                font-weight: 600;
                min-width: 155px;
            }

            > .integration-status {
                padding: 5px 20px;
                border: 1px solid black;
                border-radius: 21px;
                min-width: 100px;
                text-align: center;

                &[data-status="processing"] {
                    border-color: rgba(black, .6);
                    color: rgba(black, .6);
                }

                &[data-status="failed"] {
                    border-color: #ED2E4E;
                    color: #ED2E4E;
                }

                &[data-status="finished"] {
                    border-color: #47CE78;
                    color: #47CE78;
                }

                &[data-status="expired"] {
                    border-color: rgba(black, .3);
                    color: rgba(black, .3);
                }
            }
        }
    }
}
