.modal-list {
    > .items, > .header {
        display: grid;
        grid-template-columns: auto 13% 9% 13% 10% 12% 8% 3%;
        grid-template-rows: 1fr;
        gap: 1rem 1.1rem;
        align-items: center;

        > .custom-cell {
            position: relative;

            > .value-display {
                width: 100%;
                height: 2.7rem;
                border-radius: 7px;
                padding: 0 .8rem;
                background-color: white;
                display: flex;
                align-items: center;
            }

            > .picker {
                position: absolute;
                z-index: 100;
                transform: translateY(calc(-100% + 3px));
            }
        }
    }

    > .header {
        font-weight: 600;
        letter-spacing: .5px;
        font-size: 1rem;
        margin-bottom: 5px;
    }

    &[data-is-readonly="true"] {
        [data-component="button"] {
            visibility: hidden;
        }
    }
}