.date-range {
    display: flex;
    align-items: center;

    > .dash {
        flex: 1;
        text-align: center;
    }

    > label {
        height: 3.5rem;
        border: 1px solid #E7E5E9;
        border-radius: 7px;

        > input {
            font-size: 1rem !important;
            height: 100% !important;
        }
    }
}