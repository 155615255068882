[data-component="separator"] {
    width: 100%;
    border-bottom: 1px solid rgba(#18152B, .31);
    margin: .35rem 0;

    > .text {
        font-size: 1.14rem;
        font-weight: 600;
        padding-bottom: .5rem;
        padding-left: 2.86rem;
        text-transform: uppercase;
        letter-spacing: 1.4px;
    }
}
