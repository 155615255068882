.serial-labels-change {
    > .labels-picker {
        right: 81px;
        top: 71px;
    }

    > .confirm-button {
        @extend .clickable-solid;
        position: absolute;
        right: 111px;
        top: 68px;
        z-index: 13;
        background-color: #3abd92;
        color: white;
        padding: 3px 10px;
        border-radius: 20px;
        font-weight: 500;
    }
}
