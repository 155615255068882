.limit-modal {
    position: absolute;
    width: 1115px;
    background-color: white;
    z-index: 200;
    color: #191717;
    padding: 100px 50px;
    box-shadow: 10px 9px 33px 0 rgba(black, .16);

    > .close {
        position: absolute;
        right: 2rem;
        top: 2rem;
        cursor: pointer;
        font-size: 2.5rem;
        font-weight: 300;
    }

    > .title {
        position: relative;
        font-size: 26px;
        font-weight: 600;
        padding-bottom: 30px;

        &:after {
            content: "";
            position: absolute;
            background-color: rgba(#18152B, .3);
            height: 1px;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    > .content {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > .bubbles {
            width: 455px;
            height: 263px;
            background: url("/assets/icons/bubbles.png") no-repeat;
        }

        > .program {
            position: absolute;
            right: 40px;
            top: 40px;
            color: white;
            font-size: 26px;
            font-weight: 600;
        }

        > .info {
            font-size: 16px;
            letter-spacing: 1.6px;
            width: 500px;
            line-height: 33px;
        }
    }

    > .buttons {
        display: flex;
        align-items: center;

        > .or {
            margin: 0 30px;
        }
    }
}