@import "vars";

.terms {
    display: flex;
    margin-bottom: 10px;
    
    [data-input-type="checkbox"] {
        align-items: flex-start;
    }
}

.select-all {
    margin-top: 10px;
    > .title {
        color: $green;
    }
}

.terms-button {
    color: $green;
    text-decoration: underline;
    cursor: pointer;
}

.terms-full-text {
    max-width: 50vw;
}
