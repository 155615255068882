$header-color: #5e5e5e;

.redesigned-form {
    background-color: white;
    flex: 1;
    font-size: 8px;

    .section {
        padding: 1.1em 2.6em .3em 3em;
        position: relative;

        > .title {
            position: absolute;
            left: .4em;
            top: 50%;
            transform: rotate(180deg) translateY(50%);
            transform-origin: center;
            text-align: center;
            line-height: .9;
            letter-spacing: 2px;
            font-size: 1.37em;
            height: 100%;
            pointer-events: none;
        }

        &:nth-child(1) {
            background-color: #FAFFFC;
        }

        &:nth-child(2) {
            background-color: #F1FFF6;
        }

        &:nth-child(3) {
            background-color: #E8FFF0;
        }

        &:nth-child(4) {
            background-color: #DFFFEA;
        }

        &:nth-child(5) {
            background-color: #D6FFE4;
        }

        &[data-identity="products"] {
            padding: 2.3em 2.6em .3em 3em;

            > .title {
                text-align: end;
                height: auto;
                top: 35px;
                transform: rotate(180deg);
            }
        }

        &:first-child {
            padding: 2.2em 2.6em 0 3em;

            .sale-invoice-number {
                width: 100%;
                margin-left: -1.5em;

                > .form-entry[data-kind="text"] {
                    flex: 1;
                }
            }
        }

        .section-btn {
            display: flex;

            .button {
                font-size: 1.37em;
                margin: 0 0 auto auto;
            }
        }

        .expand {
            display: block;
            position: absolute;
            top: 7px;
            left: 4px;
            width: 155px;
            height: 13px;
            cursor: pointer;

            &:after {
                content: "";
                width: 4px;
                height: 4px;
                border: 2px solid $green;
                border-width: 0 2px 2px 0;
                margin-bottom: 2px;
                transform: rotate(45deg) translate(67%, -21%);
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        &[data-is-expanded="true"] {
            .expand {
                width: 13px;

                &:after {
                    transform: rotate(224deg) translate(-101%, -14%);
                }
            }
        }

        &[data-is-expanded="false"] {
            padding: 1.1em 2.6em 1.1em 3em;

            > .title {
                transform: rotate(0deg);
                position: relative;
            }
        }
    }

    .select-box {
        > select {
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: inherit;
            padding: 0 15px 0 4px;
        }

        &:after {
            width: 4px;
            height: 4px;
            right: 5px;
            border: 2px solid $green;
            border-width: 0 2px 2px 0;
        }
    }

    .miltiple-select {
        padding: 0 4px;

        &:after {
            border-color: $green;
            border-width: 0 2px 2px 0;
            right: 6px;
            top: calc(50% - 6px);
        }

        > .picker {
            > .item {
                margin: 4px 0;

                > label {
                    margin-bottom: 0;
                }
            }
        }

        > .face {
            padding: 0 18px 0 0;
            overflow: hidden;
            align-items: flex-start;
        }
    }

    .entry-with-btn {
        position: relative;

        .form-entry {
            width: 100%;

            .text-control {
                padding-right: 2.65em;
            }
        }

        .button {
            width: 20px;
            height: 20px;
            font-size: 1.14em;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

    .form-row {
        margin-bottom: 1.2em;

        > .form-row {
            margin-bottom: 0;
            column-gap: 8px;
        }
    }

    .form-entry {
        > .input-element {
            > .name {
                font-size: 1.14em;
                font-weight: 600;
                padding-left: .6em;
                margin-bottom: 0;
                letter-spacing: 0;
            }

            > .bubble {
                height: 20px;
                border: 1px solid #EAECF0;
                border-radius: 6px;
                font-size: 1.37em;
                padding: 0;

                > .text-control {
                    font-size: inherit;
                    padding: 0 4px;
                }

                > .date-control {
                    flex-direction: row;
                    position: relative;
                    border-radius: inherit;

                    > input {
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: inherit;
                        padding: 0 8px 0 9px;
                        border-radius: inherit;
                    }

                    > .input-button {
                        position: absolute;
                        right: 5px;
                        top: 50%;
                        transform: translateY(-50%);
                        pointer-events: none;
                        width: 17px;
                        height: 12px;
                        border-radius: 0;
                        border: none;
                        background-size: contain;
                        background-position-x: right;
                        background-image: url("/assets/icons/calendar.svg");
                    }
                }
            }
        }

        &[data-kind="bool"] {
            height: auto;
            display: flex;
            align-items: flex-end;

            > .input-element {
                height: auto;
                margin-top: 0;
                overflow: visible;
                align-items: flex-end;

                > .name {
                    margin: 0;
                    padding: 0;
                    overflow: visible;
                    word-break: normal;
                    overflow-wrap: anywhere;
                    white-space: normal;
                    height: auto;
                    font-weight: normal;
                    color: #000;
                    height: 20px;
                    display: flex;
                    align-items: center;
                }
            }

            .bubble {
                height: auto;
                border: none;
                margin-bottom: 0;
            }
        }

        &[data-kind="plainText"] {
            .bubble {
                height: 5em;
            }
        }

        &[data-is-invalid="true"] {
            > .input-element {
                > .bubble {
                    border: 1px solid $error-color;
                }
            }
        }
    }

    .product-entry {
        > .form-row {
            > .name {
                .form-entry {
                    flex: 1;
                }

                .expand-button {
                    width: 1em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    user-select: none;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        transform: rotate(180deg);
                    }
                }
            }

            .bubble {
                .text-control,
                select {
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .text-control.kind-text {
                    text-align: start;
                }

                .text-control.kind-price {
                    text-align: end;
                }
            }

            > .gross {
                display: grid;
                grid-template-columns: 1fr auto;
                align-items: center;

                > .button {
                    width: 1.35em;
                    height: 1.35em;
                    min-height: initial;
                    border: 1px solid #DCDCDC;
                    background-color: #FFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    margin: 0 0 0 4px;

                    .icon {
                        width: 6px;
                        height: 6px;
                    }
                }
            }
        }
    }

    --columns: 9fr minmax(36px, 1fr) minmax(41px, 1.3rem) minmax(83px, 2fr) minmax(83px, 2fr) minmax(48px, 1.3fr) minmax(83px, 2fr) minmax(102px, 2.38fr);

    .cost-invoice-products-section {
        .list {
            > .labels {
                > .form-row {
                    grid-template-columns: var(--columns);
                    grid-gap: 0;
                    height: 2.4em;

                    > div {
                        font-size: 1.14em;
                        font-weight: 600;
                        padding: 0 4px;
                        color: #000;
                        line-height: 1;

                        &:last-child {
                            padding-right: calc(1.35em + 8px);
                        }
                    }

                    .align-right {
                        justify-content: flex-end;
                        text-align: right;
                    }
                }
            }

            > .product-entry {
                > .form-row {
                    grid-template-columns: var(--columns);
                    grid-gap: 0;

                    .form-entry {
                        .input-element {
                            > .bubble {
                                height: 2.1em;

                                > .autocomplete > input {
                                    height: 2.1em;
                                }
                            }
                        }

                        &[data-is-invalid="true"] {
                            > .input-element {
                                > .bubble {
                                    border: 1px solid $error-color;
                                }
                            }
                        }
                    }
                }

                > .second-row {
                    animation: fade-in .2s ease;

                    > .form-row {
                        padding-left: 9%;

                        .input-element {
                            > .name {
                                font-size: 1.14em;
                            }
                        }
                    }
                }
            }
        }

        .cost-invice-summary {
            margin-top: 0;
            font-size: 1.3em;

            .summary-label {
                justify-content: flex-start;
                font-size: inherit;
                font-weight: 600;
                padding-left: 1em;
                line-height: 1;
            }

            > .form-row {
                height: 2.25em;
                grid-template-columns: var(--columns);
                grid-gap: 0;
                align-items: center;

                .bubble {
                    font-size: 1em;
                }

                > .form-entry {
                    justify-content: flex-end;

                    .text-control.kind-price {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                &:last-child {
                    border-bottom: none;

                    .form-entry {
                        grid-row: 1;
                        grid-column: 7/9;
                        justify-content: flex-end;

                        .input-element {
                            width: 100%;
                        }
                    }

                    .kind-price {
                        font-size: 1.1em;
                        color: $header-color;
                        font-weight: $bold;
                    }
                }

                .align-right {
                    display: block;
                    justify-content: flex-end;
                    padding: 0 4px;
                    text-align: right;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            > .labels {
                font-size: inherit;

                > .form-row {
                    grid-template-columns: var(--columns);
                    grid-gap: 0;

                    > div {
                        line-height: 1;
                        font-weight: 600;
                        color: #000;

                        &.align-right {
                            justify-content: flex-end;
                            padding: 0 4px;
                            text-align: right;
                        }
                    }
                }
            }
        }
    }

    .controls {
        display: flex;
        align-items: center;
        margin: 1em 0 0;
        justify-content: space-between;

        > .button {
            background-color: transparent;
            color: #000;
            flex-direction: row-reverse;
            transition: color .2s ease;
            font-size: 1.14em;
            text-transform: capitalize;

            .icon {
                width: 1.36em;
                height: 1.36em;
            }

            &:hover {
                color: #41B481;
                background-color: transparent;
            }
        }

        .input-element {
            margin-top: 0;

            .name {
                padding: 0;
            }
        }

        .checkbox {
            height: 1.36em;

            &__mark {
                width: 1.36em;
                height: 1.36em;
            }
        }
    }

    .attachments {
        > .button {
            font-size: 1.37em;
        }

        .list {
            margin-top: 15px;

            .attachment {
                .button {
                    width: 24px;
                    padding: 0;

                    .icon {
                        filter: brightness(0) invert(1);
                        width: 40%;
                        height: 40%;
                    }
                }
            }
        }
    }

    .duplicate {
        .form-entry[data-is-invalid="true"] {
            > .error-bubble {
                opacity: 1;
            }
        }
    }
}
