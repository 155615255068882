@import "vars";

$bar-height: 4px;

.range-picker {
    position: relative;

    > .manual {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.3rem;

        > .manual-input {
            > .title {
                width: auto;
                margin-right: 15px;
                font-size: 1rem;
                font-weight: 600;
            }

            > input {
                height: 3.5rem;
                border-radius: 7px;
                border: 1px solid #E7E5E9;
                outline: none;
                font-size: 1rem;
                padding: 0 22px;
                width: 126px;
            }
        }
    }

    > .bar {
        position: absolute;
        top: 2px;
        width: 100%;
        height: $bar-height;
        background-color: #E7E5E9;
        border-radius: 10px;
    }

    > .full-bar {
        position: absolute;
        top: 2px;
        height: $bar-height;
        background-color: $green--darker;
    }

    > .mark {
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: $green--darker;
        transform: translateX(-50%);
        border-radius: 50%;
        cursor: pointer;

        &:before {
            content: "";
            position: absolute;
            width: 32px;
            height: 32px;
            left: -12px;
            top: -12px;
            background-color: rgba($green--darker, 0.5);
            transform: scale(0.7);
            border-radius: 50%;
            transition: transform .3s cubic-bezier(0.19, 1, 0.22, 1);
        }

        &:hover {
            &:before {
                transform: none;
            }
        }
    }
}