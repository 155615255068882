@import "_vars";

// fonts
$light: 300;
$regular: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

@font-face {
    font-family: "Barlow";
    src: url("/assets/fonts/Barlow-Regular.otf");
    font-weight: $normal;
}

@font-face {
    font-family: "Barlow";
    src: url("/assets/fonts/Barlow-SemiBoldItalic.otf");
    font-weight: $semibold;
    font-style: italic;
}

@font-face {
    font-family: "Barlow";
    src: url("/assets/fonts/Barlow-Medium.otf");
    font-weight: $medium;
}

@font-face {
    font-family: "Barlow";
    src: url("/assets/fonts/Barlow-Light.otf");
    font-weight: $light;
}

.number-text {
    font-family: monospace !important;
    font-size: 13px !important;
}

[data-input-type="price"], [data-input-type="number"] {
    input {
        @extend .number-text;
        text-align: right;
    }
}

[data-input-type="text"] {
    [data-is-monospace="true"] {
        @extend .number-text;
    }
}

.pagination {
    display: flex;
    align-items: center;
    background-color: white;
    box-shadow: 0 0 16px 1px rgba(black, .16);
    padding-left: 20px;
    border-radius: 10px;

    [data-component="input"] {
        padding: 1.2rem;

        .input {
            background-color: transparent;
            height: auto;
            padding: .5rem;
            width: 4.4rem;
            cursor: pointer;
        }
    }

    [data-input-type="select"] {
        &[data-is-capsuled="false"]:after,
        &[data-is-capsuled="true"] .input-outer:after {
            height: .5rem;
            width: .5rem;
        }
    }
}

[data-icon-name="more"] {
    padding-right: .7rem;

    svg {
        width: 1.3rem;
        height: auto;
    }
}

.alert {
    margin: auto;
    width: 65%;
    height: 100vh;
    font-size: 1.5rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;

    a {
        margin-top: 1.5rem;
        text-decoration: none;
        font-style: italic;
        color: #39BD92;
    }
}

.nip-with-gus-container {
    position: relative;
    display: flex;
    align-items: flex-end;

    [data-input-type="search"] {
        flex: 1;
    }

    [data-input-type="text"] {
        flex: 1;
    }

    > [data-input-type="select"] {
        margin-right: 5px;
    }
}

.logged-out {
    a {
        color: $green;
    }
}

.hideen-scrollbars::-webkit-scrollbar {
    display: none;
}

.hideen-scrollbars {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.loads-content {
    opacity: 1;
    transition: opacity 0.2s;
    
    &[data-is-loading="true"] {
        opacity: 0.38;
        pointer-events: none;
    }
}

.clickable-solid {
    transition: opacity .21s;

    &:hover {
        opacity: .61;
        cursor: pointer;
    }

    &:active {
        transition: none;
        opacity: .38;
    }
}

@keyframes context-appear {
    from {
        transform: translateY(-10px);
        opacity: .5;
    }

    to {
        transform: none;
        opacity: 1;
    }
}

.context-appear {
    animation: context-appear .2s cubic-bezier(.175, .885, .32, 1.275);
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
