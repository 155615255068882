.select-box {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    > select {
        width: 100%;
        height: 100%;
        border: none;
        background-color: transparent;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
    }

    &:after {
        display: block;
        content: "";
        height: .57rem;
        width: .57rem;
        border: 3px solid #47CE78;
        margin-bottom: 2px;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        pointer-events: none;
        position: absolute;
        right: 8px;
    }
}