.settlement-search-input {
    display: grid;
    display: grid;
    grid-template-columns: 1fr 1.2fr 1fr .6fr auto;
    column-gap: 12px;

    .search-item {
        display: flex;
        white-space: nowrap;
        align-items: center;
        gap: 5px;

        .input-pre {
            margin-right: 5px;
            font-weight: 600;
            text-align: left;
            font-size: 11px;
            letter-spacing: .5px;
            height: 17px;
        }

        .form-entry[data-kind="date"] {
            width: 135px;
        }
    }

    .form-entry {
        flex: 1;

        > .input-element {
            > .name {
                display: none;
            }
        }
    }
}
