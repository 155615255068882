[data-component="table"] [data-component="cell"] {
    padding: .7rem;
    line-height: 1.3rem;
    letter-spacing: .07rem;
    word-break: break-word;
    display: flex;

    &[data-cell-type="title"] {
        font-weight: bold;
    }

    &[data-cell-type="status"] {
        height: 100%;
        color: white;
        text-align: center;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: .7rem;

        &[data-status="2"] {
            background-color: #FC6B8A;
        }

        &[data-status="1"] {
            background-color: #42D2A3;
        }

        &[data-status="3"] {
            background-color: #688CFF;
        }
    }
}
