.transaction-details {
    width: 100%;
    padding: 25px 23px;

    .search-label {
        margin-top: 18px;

        &__title {
            background-color: #f1f0f5;
            padding: 12px 13px 0;
            font-weight: 600;
            text-align: left;
            font-size: 11px;
            letter-spacing: .5px;
            border-radius: 7px 7px 0 0;
        }
    }

    .setlement-section {
        margin-top: 28px;

        [data-component="separator"] {
            .text {
                padding-left: 0;
            }
        }
    }
}