.categories-list {
    > .summary {
        display: flex;
        margin-right: 43rem;
        justify-content: flex-end;

        > .part {
            display: grid;
            grid-template-columns: 150px 150px 150px;
            grid-auto-rows: 30px;
            row-gap: 9px;
            margin-right: 2.14rem;

            > div {
                background-color: white;
                display: flex;
                align-items: center;
                padding-left: 20px;
            }

            > .label-key {
                font-size: 11px;
                font-weight: 700;
            }
        }
    }
}
