.role-definition-details {
    width: 100%;
    height: 50vh;
    padding: 1.07rem 2.14rem 1.07rem 2.86rem;

    .permission-row {
        width: 100%;
        display: flex;
        margin-top: 15px;

        > :first-child {
            flex: 1;
            margin-right: 20px;
        }

        > :last-child {
            flex: 2;
        }

        > [role="button"] {
            margin-top: 15px;
        }
    }
}
