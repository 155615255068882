.autocomplete {
    width: 100%;
    box-shadow: none;
    background-color: transparent;

    &[data-is-open="true"] {
        background-color: white;
        box-shadow: 0 0 20px rgba(0,0,0,.2);
    }

    > input {
        height: 100%;
        width: 100%;
        border: none;
        background-color: transparent;
        font-size: inherit;
    }

    > .suggestions {
        position: absolute;
        z-index: 1;
        padding: 0;
        background-color: white;
        box-shadow: 0 0 20px rgba(0,0,0,.2);
        max-height: 50vh;
        overflow: scroll;

        > .option {
            padding: 5px;

            &:hover {
                background-color: #47ce78;
                color: white;
            }
        }
    }
}
