@import "style-base";

$error-color: #ED2E4E;

.form-entry {
    position: relative;

    > .error-bubble {
        position: absolute;
        z-index: 4;
        bottom: 100%;
        left: 50%;
        padding: .5em 1em;
        white-space: nowrap;
        color: white;
        font-size: .8em;
        line-height: 1.7em;
        background-color: $error-color;
        opacity: 0;
        transform: translate(-50%, 0);
        border-radius: 4px;
        box-shadow: 0 0 20px rgba(black, .2);
        transition: opacity .2s, transform .2s ease-out;
        pointer-events: none;

        &:after {
            position: absolute;
            content: "";
            z-index: 3;
            display: block;
            left: 50%;
            bottom: 0;
            width: 10px;
            height: 10px;
            margin-bottom: -4px;
            content: "";
            background-color: $error-color;
            transform: translate(-50%, 0) rotate(45deg);
        }
    }

    &[data-is-invalid="true"] {
        > .input-element {
            > .bubble {
                border: 1px solid $error-color;
            }
    
            > .name {
                color: $error-color;
            }
        }

        &:hover {
            > .error-bubble {
                opacity: 1;
                transform: translate(-50%, -10px);
            }
        }
    }

    > .input-element {
        color: black;

        > .name {
            font-weight: 600;
            text-align: left;
            margin-bottom: 3px;
            font-size: 11px;
            letter-spacing: .5px;
            height: 17px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        > .bubble {
            background-color: white;
            height: 30px;
            border-radius: 7px;
            padding: 0 9px;
            cursor: pointer;

            > .miltiple-select {
                min-height: unset;
                height: 100%;
                border: none;

                > .face {
                    padding: 0;
                    min-height: unset;
                    height: 100%;
                    font-size: 13.3px;
                }
            }
        }

        > .create-button {
            display: none;
            @extend .clickable-solid;
            $width: 25px;
            position: absolute;
            right: $width;
            bottom: 19px;
            background-position: center center;
            background-repeat: no-repeat;
            width: $width;
            height: $width;
            background-image: url("/assets/icons/add.svg");
        }

        &[data-has-creator="true"] {
            > .bubble {
                margin-right: 34px;
            }
        }
    }

    &[data-kind="bool"] {
        height: 50px;

        > .input-element {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: center;
            margin-top: 20px;
            height: 30px;

            > .name {
                margin: 0;
            }

            > .bubble {
                background-color: transparent;
                height: unset;
                padding: 0;
                margin-bottom: 12px;
            }
        }
    }
}
