.attachments {
    > .list {
        display: flex;

        > .attachment {
            position: relative;
            margin-right: 10px;

            > .preview {
                width: 100px;
                height: 100px;

                > img {
                    width: inherit;
                    height: inherit;
                }
            }

            > .button {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
}
