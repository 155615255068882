@import "style-base";

.add-income--form {
    transition: opacity .3s;

    > [data-input-type="checkbox"] {
        margin-left: calc(23% + 10px);
    }

    [data-component="input"] .input {
        font-size: 11px;
        height: 20px;
    }

    [data-component="input"][data-input-type="select"] .input {
        padding-right: 16px !important;
    }

    .check {
        transform: scale(0.8);
    }

    .small-tick {
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -5px;
        right: 0.63rem;
        height: 0.37rem;
        width: 0.37rem;
        border: 2px solid #47CE78;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        opacity: 1;
        transition: opacity .3s;
        pointer-events: none;
    }

    [data-component="input"][data-input-type="select"][data-is-capsuled="true"] .input-outer:after {
        @extend .small-tick;
    }

    .select-box:after {
        @extend .small-tick;
    }

    [data-component="input"][data-input-type="select"][data-is-capsuled="false"]:after {
        @extend .small-tick;
    }

    .miltiple-select:after {
        @extend .small-tick;
        top: calc(50% - 2px) !important;
        width: 0.4px;
        height: 0.4px;
    }

    [data-component="input"][data-input-type="date"] .input-button {
        background-size: 1.19rem 1.54rem;
        right: -4px;
    }

    .subject-tabs {
        display: flex;
        padding-left: 10px;
        margin-top: 10px;
        position: relative;
        
        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background-color: rgba(24, 21, 43, 0.31);
        }

        > [data-input-type="checkbox"] {
            position: absolute;
            right: 20px;
        }

        > div {
            @extend .clickable-solid;
        }

        .selected-tab {
            font-weight: bold;
        }

        &[data-subject="seller"] {
            > :first-child {
                @extend .selected-tab;
            }
        }

        &[data-subject="receiver"] {
            > :last-child {
                @extend .selected-tab;
            }
        }

        > :first-child {
            width: 100px;
        }

        > :last-child {
            flex: 1;
        }
    }

    .miltiple-select {
        font-size: 11px !important;
        height: 20px !important;
    }

    .new-order-modal {
        .modal-body {
            flex-direction: column;
        }

        .with-bg {
            visibility: visible !important;
        }

        .with-padding {
            position: unset !important;
        }
    }

    .origin-picker {
        display: flex;
        margin-left: 22px;

        > div {
            @extend .clickable-solid;
            padding: 10px;
            font-size: 11px;
            font-weight: 600;
        }
    }

    .modal-content {
        .with-bg {
            visibility: hidden;
            width: 0;
        }

        .with-padding {
            position: absolute;
        }

        .order-row {
            display: grid;
            grid-gap: .7rem;
            grid-template-rows: 1fr;
            grid-template-columns: 1fr 1fr 1fr 1fr;

            > [data-input-type="text"] {
                margin-left: 5px;
            }

            > label {
                pointer-events: none;
            }

            &:hover {
                cursor: pointer;
                opacity: .61;
            }
        }

        .table-header {
            display: flex;
            
            > div {
                flex: 1;
                margin-left: 5px;
                pointer-events: none;

                &:before, &:after {
                    visibility: hidden;
                }
            }
        }

        > .modal-body {
            padding: 20px;

            > .order-row {
                &:hover {
                    cursor: pointer;
                    opacity: .61;
                }

                > div, label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    .advances {
        padding: 2.2rem 10px .6rem;
        
        > .labels, > .advance {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 1.1rem;
            margin-bottom: .6rem;
        }
    }

    .advance-row {
        padding: 2.2rem 10px 2rem;
        display: grid;
        grid-template-columns: auto repeat(6, 1fr);
        column-gap: 1.1rem;
        margin-bottom: .6rem;

        > [role="button"] {
            margin-top: 17px;
            width: 100px;
            height: 30px;
            min-height: unset;
            min-width: unset;
        }

        > .name {
            font-size: 1.14rem;
            font-weight: 600;
            align-self: flex-end;
            padding-bottom: 6px;
            margin-right: 10px;
        }
    }

    .readonly-name {
        display: inline-block;
        box-sizing: border-box;
        width: 100%;
        height: 3.2rem;
        line-height: 3.2rem;
        border-radius: 7px;
        padding: 0 .8rem;
        font-size: 1.04rem;
        border: 1px solid transparent;
        transition: border-color .3s;
        outline: none;
        background-color: white;
        font-family: "Poppins", sans-serif;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .procedure {
        > .title {
            font-weight: 600;
            font-size: 1rem;
            margin-bottom: 0;
            letter-spacing: .5px;
        }

        .miltiple-select {
            background-color: white;
            height: 2.7rem;
            min-height: unset;
            border: none;
            padding-left: 10px;
            margin-top: 4px;

            > .face {
                padding: 0;
                min-height: unset;
                padding-top: 2px;
                height: 100%;
            }
        }
    }

    .advance-header {
        padding-left: 41%;
        font-weight: bold;
        font-size: 1.3rem;
    }

    // &[data-is-loading="true"] {
    //     opacity: .5;
    //     pointer-events: none;
    // }
    .title {
        margin-bottom: -4px !important;
        font-size: 9px !important;
    }

    > .form-main {
        padding: 10px 10px .6rem;
        display: grid;
        grid-template-columns: 1fr 1fr repeat(3, 2fr);
        grid-template-rows: repeat(2, 1fr);
        gap: .6rem 1.1rem;
        grid-template-areas: "f1 f1 f2 f3 f4 " "f9 f5 f6 f7 f8 " "f12 f11 f11 f10 f10 ";
    }

    .form-seller {
        padding: 2.5rem 2rem 3.6rem 10px;
        padding-top: 4px;
        padding-bottom: 0;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);
        gap: .6rem 1.1rem;
        grid-template-areas: "f1 f1 f2 f2" "f3 f3 f4 f5 " "f6 f7 f7 . " "f8 f8 f9 f9 ";
    }

    .form-buyer {
        position: relative;
        padding: 1.2rem 10px;
        padding-top: 4px;
        padding-bottom: 0;

        > div {
            gap: .6rem
        }

        .row1 {
            display: grid;
            grid-template-areas: "f1 f2 f3 f4 f5";
            grid-template-columns: auto 90px 185px 90px 90px;
        }

        .row2 {
            display: grid;
            grid-template-areas: "f6 f7 f8 f9 f10 f11";
            grid-template-columns: auto 64px 13px 65px 120px 70px;

            > :nth-child(2) {
                input {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    text-align: right;
                    width: calc(100% - 17px);
                }

                .title {
                    width: 100px;
                }
            }
    
            > :nth-child(3) {
                > .title {
                    color: transparent;
                }
    
                > .input-outer {
                    position: relative;
    
                    &:before {
                        content: "/";
                        position: absolute;
                        left: -30px;
                        top: 3px;
                        width: 10px;
                        height: 18px;
                        z-index: 100;
                    }
    
                    > input {
                        position: absolute;
                        left: -30px;
                        width: calc(100% + 30px);
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
    
                }
            }
        }

        .gus-button {
            top: auto;
            bottom: 0;
            height: 20px;
            border-radius: 0 7px 7px 0;
            width: unset;
            padding: 0 5px;
        }

        [data-input-type="checkbox"] {
            align-items: flex-end;
        }
    }

    > .form-bank {
        padding: 0 10px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        gap: .8rem 1.1rem;
        grid-template-areas: "f1 f2";
    }

    > .spacing {
        padding-top: 1.8rem;
    }

    .form-list {
        padding: 0 10px;

        > .header {
            font-weight: 600;
            letter-spacing: .5px;
            font-size: 9px;
        }

        .item {
            height: 24px;
        }

        &[data-is-readonly="true"] {
            .item {
                margin-bottom: 5px;
            }
        }

        [data-input-type="checkbox"] {
            height: 100%;

            > .checkbox-input {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;

                > .check {
                    position: relative;
                }
            }
        }

        > .item, > .header {
            display: grid;
            grid-template-columns: auto minmax(50px, 6%) minmax(48px, 6%) 14% 14% 49px 14% 25px 8px;
            grid-template-rows: 1fr;
            gap: .6rem;
            align-items: center;
        }

        > .footer {
            width: 100%;

            .icon {
                transform: scale(0.8);
            }

            > [role="button"] {
                padding: 0;
            }
        }

        [data-component="button"] {
            padding: .8rem 0;
        }

        &-readonly {
            .item span {
                display: inline-block;
                box-sizing: border-box;
                width: 100%;
                height: 3.2rem;
                line-height: 3.2rem;
                border-radius: 7px;
                padding: 0 .8rem;
                font-size: 1.04rem;
                border: 1px solid transparent;
                transition: border-color .3s;
                outline: none;
                background-color: white;
                font-family: "Poppins", sans-serif;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    > .form-summary {
        display: grid;
        grid-template-columns: 2fr 8fr;
        grid-template-rows: 1fr;
        grid-template-areas: "add inner";
        padding: 2rem 10px 3rem;
        padding-top: 0;
        padding-bottom: 6px;
        grid-gap: 10px;

        > .form-add {
            grid-area: add;
            display: flex;
            flex-direction: column;

            > .form-hid {
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;

                [data-component="input"] {
                    flex: 1;
                    margin-right: 10px;
                }
            }

            > .invoice {
                padding: 10px 15px;
                background-color: rgba(white, .5);
                display: flex;

                > div {
                    width: 30%;

                    &:first-child {
                        flex: 1;
                    }

                    &:last-child {
                        width: 10%;
                    }
                }

                &-header {
                    font-weight: bold;
                }

                &:nth-child(odd) {
                    background-color: rgba(white, .8);
                }
            }
        }
    }

    > .form-other {
        padding: 3rem 10px;
        padding-top: 0;
        display: grid;
        grid-template-columns: repeat(4, 15.6rem) 1fr;
        grid-template-rows: 1fr .7fr 1fr 1fr auto;
        gap: .5rem;
        grid-template-areas:
            // "f1 f1 f1 f1 ."
            "f2 f2 f3 f3 ."
            "f4 f4 f10 f10 ."
            "f5 f5 f6 f6 ."
            "f7 f7 f7 f7 ."
            // "f8 f8 . . ."
            "f11 f11 f11 f11 ."
            "f9 f9 f9 f9 .";

        [data-component="button"].with-bg {
            width: 20.6rem;
            height: 3.7rem;
            text-transform: uppercase;
            margin-top: 0;
        }
    }

    .form-summary-inner {
        > div {
            height: 20px;
            margin-top: 1px;
        }
        
        > .wide-row {
            line-height: unset;
        }

        > .data-row {
            padding: 0;
        }

        > .row:last-child {
            height: unset;

            > div {
                line-height: unset;
            }
        }
    }

    .big {
        font-size: 11px !important;
    }

    .separator-row {
        display: none !important;
    }

    [data-is-discount-hidden="true"] .row:not(.wide-row) {
        grid-template-columns: 3fr 2fr 3fr 3fr 3fr 30px !important;
        grid-gap: 0 !important;
    }
}


.form-summary-inner {
    grid-area: inner;

    > .row {
        margin-top: .57rem;
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr 3fr 3fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "f1 f2 f3 f4 f5 f6 f7";

        > .currency-column {
            text-align: center;
            font-size: 1rem;
        }

        > .title-column {
            text-align: left;
            font-weight: 600;
            padding-left: 2.15rem;
        }

        &:last-child {
            height: 4.07rem;

            .big {
                line-height: 4.07rem;
            }

            .currency-column {
                line-height: 3.4rem;
            }
        }
    }

    &[data-is-discount-hidden="true"] .row:not(.wide-row) {
        grid-template-columns: 3fr 2fr 3fr 3fr 3fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "f1 f2 f4 f5 f6 f7";
        grid-gap: 1.2rem;
    }

    > .title-row {
        font-weight: bold;
        text-align: right;
        letter-spacing: .5px;
    }

    > .data-row {
        text-align: right;
        background-color: white;
        padding: 1rem 0;
    }

    > .separator-row {
        margin-top: 30px;
    }

    > .wide-row {
        background-color: white;
        grid-template-columns: 3fr 2fr 2fr 2fr 3fr 3fr 30px !important;
        grid-template-areas: "f1 f1 f1 f2 f2 f2 f3";
        line-height: 3.07rem;
        text-align: right;

        > .big {
            font-size: 1.86rem;
            font-weight: 600;
        }
    }
}
