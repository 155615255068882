@import "style-base";

.table-sum {
    margin-top: 8px;

    .switch {
        display: flex;
        background-color: rgba(white, .81);
        border-radius: 8px;
        opacity: 0;
        transform: translateY(10px);
        transition: opacity .3s;
        transition-property: opacity, transform;

        .selected-alternative {
            background-color: #39BD92;
            color: white;
            border-radius: 8px;
        }
        
        &[data-is-on-right="false"] {
            > :first-child {
                @extend .selected-alternative;
            }

            > :last-child {
                @extend .clickable-solid;
            }
        }

        &[data-is-on-right="true"] {
            > :first-child {
                @extend .clickable-solid;
            }

            > :last-child {
                @extend .selected-alternative;
            }
        }

        > .switch-alternative {
            padding: 3px 9px;
        }
    }

    &[data-is-switchable="true"] {
        .switch {
            opacity: 1;
            transform: none;
        }
    }

    .icon {
        background-size: 1.69rem 1.54rem;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 40% 40%;
        width: 100%;
        height: 100%;

        &[data-operation="sum"] {
            background-image: url("/assets/icons/stat_sum.svg");
        }

        &[data-operation="average"] {
            background-image: url("/assets/icons/stat_average.svg");
        }

        &[data-operation="min"] {
            background-image: url("/assets/icons/stat_min.svg");
        }

        &[data-operation="max"] {
            background-image: url("/assets/icons/stat_max.svg");
        }
    }

    > .tip {
        display: flex;
        margin-bottom: 8px;
        align-items: flex-end;

        > .header {
            width: 100px;

            select {
                @extend .clickable-solid;
                margin-left: 11px;
                cursor: pointer;
            }
            
            font-size: 14px;
            margin-right: 10px;
        }
    }

    > .pairs {
        height: 32px;
        display: grid;
        grid-template-columns: var(--layout);
        grid-template-rows: 1fr;

        > .control-cell {
            display: flex;
            flex-direction: row-reverse;
            position: relative;

            > .currency-switch {
                margin-right: 10px;

                > .select-box {
                    background-color: white;
                    border: 1px solid #39BD92;
                    border-radius: 7px;

                    > select {
                        padding: 0 22px 0 14px;
                    }
                }
            }

            > .top {
                position: absolute;
                display: flex;
                right: 0;
                transform: translateX(100%);
                align-items: flex-end;
                top: -23px;

                > .header {
                    font-size: 14px;
                    margin-right: 11px;
                }
            }

            > .operation-switch {
                @extend .clickable-solid;
                width: 44px;
                height: 100%;
                // background-color: rgba(#83909a, .81);
                // background-color: rgba(#cfe2f3, .81);
                background-color: white;
                border-radius: 7px;
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
                border: 1px solid rgba(#39BD92, .6);
                margin-right: 1px;
            }

            > .stats-select {
                position: absolute;
                @extend .context-appear;
                background-color: white;
                box-shadow: 0 0 16px 1px rgba(black, .16);
                border-radius: 6px;
                padding: 4px 10px;

                > .stat-select {
                    @extend .clickable-solid;
                    padding: 8px 0;
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    justify-content: flex-end;

                    > .icon {
                        width: 15px;
                        height: 15px;
                        margin-right: 11px;
                        background-size: 100% 100%;
                    }
                }
            }
        }
        
        > .stat {
            padding: 8px 9px;
            background-color: rgba(#cfe2f3, .81);
            margin-right: 1px;
            border-radius: 3px;

            > .name {
                font-size: 11px;
                font-weight: 700;
                margin-bottom: 5px;
            }

            > .sum {
                font-size: 12px;
                font-family: monospace;
                text-align: end;
            }
        }
    }
}