@import "style-base";

.user-data {
    @extend .hideen-scrollbars;

    > .disk-limit {
        padding-left: 4rem;
        margin-top: 30px;
        font-size: 14px;

        > div {
            display: flex;
        }

        .key {
            font-weight: 600;
            width: 250px;
        }
    }

    .clear-user-data {
        padding-left: 4rem;
        margin-top: 88px;
    }

    .form {
        position: relative;
        width: 61%;
        min-width: calc(790px - 10rem);
        padding-left: 11.8rem;
        margin: 0 2.86rem;
        box-sizing: content-box;

        > .avatar-picker {
            position: absolute;
            left: 0;
            top: -.7rem;
            z-index: 100;
        }
    }

    > .title {
        font-size: 1.86rem;
        font-weight: 600;
        margin: 4rem 0 3rem 2.86rem;
    }

    > .form {
        position: relative;
        display: grid;
        grid-gap: .5rem 2.78rem;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr 1fr auto;
        grid-template-areas: "f1 f1 f2 f2" "f3 f3 f4 f4" ". . . f5";

        [data-component="input"], [data-component="input"] .input {
            height: 3.8rem;
        }

        label {
            height: 3.8rem;
            background-color: white;
            flex-direction: row;
            align-items: center;

            > .title {
                width: 15.38rem;
                padding-left: 1.3rem;
                margin-bottom: 0;
            }
        }

        [data-component="button"] {
            margin-top: 1rem;
        }
    }
}
