@import "style-base";

[data-component="horizontal-nav"] {
    @extend .hideen-scrollbars;
    width: 100%;
    height: 2.4rem;
    line-height: 1.3rem;
    border-bottom: 1px solid rgba(#18152B, .31);
    display: flex;
    flex-direction: row;
    user-select: none;
    box-sizing: border-box;

    @media print {
        display: none;
    }

    > .item {
        padding: 0 3.6rem;
        color: transparent;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        transition: border-bottom-color .3s, border-bottom-width .3s;
        margin-right: 5px;
        position: relative;
        height: calc(2.4rem + 2px);
        text-transform: uppercase;
        font-size: 1.14rem;

        &:last-child {
            margin-right: 0;
        }

        &:before {
            content: "";
            height: 2px;
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            background: #191717;
            transform: scaleX(0);
            transition: all .2s ease;
        }

        &:hover, &[data-is-active="true"] {
            &:before {
                transform: scaleX(1);
            }
        }

        &:after {
            content: attr(data-text);
            color: #191717;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 17%;
            text-align: center;
            font-weight: 300;
            letter-spacing: 1.4px;
        }

        &[data-is-active="true"] {
            &:after {
                font-weight: 600;
            }
        }
    }
}
