.time-picker {
    .form-entry {
        width: 50px;
    }

    .bubble {
        width: 60px;
    }

    input {
        width: 100%;
        height: 100%;
        text-align: center;
        border: none;
    }
}
