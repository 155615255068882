.image-window {
    width: 100vw;
    height: 100vh;

    > .loading-file {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    > div {
        > div {
            > div {
                height: 100%;
                width: 100%;

                > img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}
