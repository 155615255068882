.button {
    border: none;
    color: #FFF;
    text-transform: uppercase;
    background-color: $green;
    border-radius: 6px;
    font-size: 11px;
    min-height: 24px;
    padding: 2px 9px;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5em;
    transition: background-color .2s ease;

    &:hover {
        background-color: #0EC550;
    }

    .icon {
        max-width: 95%;
        max-height: 95%;
    }
}