@import "_vars";

[data-component="pager"] {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: .7rem;
    font-size: 1.1rem;

    > .item, > .item-prev, > .item-next {
        margin-left: 1.1rem;
    }

    > .item {
        padding: 3px 8px;
        border-radius: 5px;
        background-color: transparent;
        transition: background-color .3s, color .3s;
        cursor: pointer;

        &[data-is-current="true"], &:hover {
            background-color: $green;
            color: white;
        }

        &-space {
            margin: 0 .2rem 0 1.6rem;
        }
    }

    > .item-prev, > .item-next {
        color: $green;
        padding: 3px 8px;
        transition: background-color .3s, color .3s;
        border-radius: 5px;
        background-color: transparent;
        cursor: pointer;
        display: flex;

        img {
            width: 1rem;
            height: auto;
        }
    }
}
