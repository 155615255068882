.labels-cell {
    position: relative;
    cursor: pointer;

    > .selected-labels {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 .8rem;

        @mixin label-arrow {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                top: 100%;
                left: 0;
                width: 0;
                height: 0;
                border-color: currentColor transparent;
                border-style: solid;
                border-width: 3px 4px 0;
            }
        }

        > .label {
            width: 8px;
            height: 10px;
            margin: 0 2px 3px;
            border-radius: 1px 1px 0 0;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                top: 100%;
                left: 0;
                width: 0;
                height: 0;
                border-color: currentColor transparent;
                border-style: solid;
                border-width: 3px 4px 0;
            }
        }
    }

    .label-tooltip {
        pointer-events: none;
        position: absolute;
        top: 23px;
        left: 16px;
        background-color: white;
        padding: 6px 10px;
        border-radius: 6px;
        box-shadow: 0 0 16px 1px rgba(black, .16);

        > .label-in-picker {
            margin-bottom: 3px;

            > .tag {
                visibility: hidden;
            }
        }
    }
}

.page--outer[data-is-collapsed="false"] {
    .labels-picker:before {
        left: 170px;
    }
}