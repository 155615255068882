.transactions-list-table {
    .single-modal {
        align-items: start;
        padding-top: 8px;
    }

    .header {
        position: absolute;
        top: 40px;
        right: 24px;
    }

    .modal-content {
        width: 99vw !important;
    }

    .selection-actions-bar {
        position: fixed;
        top: 43px;
        right: 400px;
    }
}

.registry-identity-display {
    padding-left: 2.86rem;
    padding-top: 1.07rem;
    margin-bottom: -10px;
    font-weight: 600;
    font-size: 14px;
}
