.product-entry {
    > .form-row {
        > .name {
            display: flex;

            > .expand-button {
                @extend .clickable-solid;
            }
        }

        > .gross {
            display: flex;
        }
    }

    .form-entry {
        > .input-element {
            > .name {
                display: none;
            }
            > .bubble {
                > .autocomplete {
                    height: 100%;
                }
            }
        }
    }
}
