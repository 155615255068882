.clear-user-data {
    .modal-footer {
        height: 0;
        display: none;
    }

    .confirm {
        padding: 20px;

        .controls {
            display: flex;
            align-items: end;
        }

        .tip {
            margin-bottom: 20px;
            margin-left: 20px;
        }
    }
}