@import "style-base";

.expandable-section {
    > .title {
        position: relative;
        display: flex;
        font-size: 1.14rem;
        font-weight: 600;
        padding-left: 2.86rem;
        text-transform: uppercase;
        letter-spacing: 1.4px;
        align-items: center;
        height: 60px;
        
        > .expand-button {
            @extend .clickable-solid;
            // background: url("/assets/icons/expand_button.svg") no-repeat center center;
            // background: url("https://i.postimg.cc/SNqkrGM1/expand-button.png") no-repeat center center;
            background: url("https://svgur.com/i/Whc.svg") no-repeat center center;
            height: 26px;
            width: 26px;
            margin-right: 15px;
            transform: none;
        }

        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background-color: rgba(#18152B, .31);
        }
    }

    > .cropping {
        overflow: hidden;
        transition: opacity .3s;
        transition-property: opacity, height;
    }

    &[data-is-collapsed="true"] {
        background-color: white;

        .copping {
            opacity: 0;
        }

        .expand-button {
            transform: rotate(-90deg);
        }
    }
}