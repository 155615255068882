.date-control {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    > input {
        height: 100%;
        border: none;
    }

    > .input-button {
        height: 20px;
        width: 4rem;
        align-items: center;
        background-image: url("/assets/icons/calendar.svg");
        background-size: 1.69rem 1.54rem;
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 0 7px 7px 0;
        border: 0 solid transparent;
        transition: opacity .3s, border-color .3s;
        cursor: pointer;
        pointer-events: all !important;
    }

    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }

    input[type="date"]::-moz-calendar-picker-indicator {
        display: none;
    }
}
