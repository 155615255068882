.invoice-preview {
    background: white;
    min-height: 100vh;
    width: 69rem;
    margin-left: auto;
    box-shadow: -9px 17px 45px 1px rgba(0, 0, 0, .11);
    position: relative;
    padding-top: 22px;

    @media print {
        width: 100%;
        min-height: auto;
        box-shadow: none;
        font-size: .96rem;
        padding: 0;
    }

    [data-component="table"] {
        @media print {
            margin: 9px 0 8px;
        }

        > .table-body > .row > div {
            display: block !important;
        }

        > .table-body {
            > .row {
                height: unset;

                > .cell {
                    white-space: normal;
                }
            }
        }
    }

    .title-row, .data-row {
        grid-gap: 0 !important;
        margin-top: 2px !important;
        height: unset !important;

        @media print {
            grid-gap: 0 6px !important;
        }
    }

    .content {
        margin-top: -3px;

        @media print {
            margin-top: 0;
            padding: 3px 0 0;
            line-height: 14px;
        }
    }

    .data {
        margin-top: -3px !important;

        @media print {
            margin-top: 0 !important;
        }
    }

    .header-field {
        font-size: .66em;

        > .content {
            font-weight: normal;
        }
    }

    .advance-header {
        padding-left: 42%;
        font-weight: bold;
        font-size: 1.3em;
        margin: 4px 0 0;

        @media print {
            margin: 13px 0 0;
        }
    }

    .title {
        font-weight: 600;
        letter-spacing: .5px;

        @media print {
            font-size: 1.1em;
            line-height: 17px;
        }
    }

    .table-title {
        font-weight: 600;
        letter-spacing: .5px;
        padding: 17px 12px 11px;
    }

    .content {
        @media print {
            background-color: white;
        }
    }

    .invoice-preview-summary {
        display: grid;
        grid-template-columns: auto minmax(auto, 54%);
        grid-template-rows: auto-fill;
        grid-template-areas: "add inner";
        padding-right: 11px;
        margin-left: auto;
        margin-top: 3px;

        @media print {
            padding: 6px 11px 0;
            margin-top: 0;
            grid-template-columns: auto minmax(auto, 52%);
        }

        > .form-add {
            grid-area: add;
            display: flex;
            flex-direction: column;

            > .form-hid {
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;

                [data-component="input"] {
                    flex: 1;
                    margin-right: 10px;
                }
            }

            > .invoice {
                padding: 10px 15px;
                background-color: rgba(white, .5);
                display: flex;

                > div {
                    width: 30%;
                    &:first-child {
                        flex: 1;
                    }
                }

                &-header {
                    font-weight: bold;
                }

                &:nth-child(odd) {
                    background-color: rgba(white, .8);
                }
            }
        }

        > .invoice-preview-summary-inner {
            grid-area: inner;

            > .row {
                margin-top: 6px;
                display: grid;
                grid-template-columns: 2fr 2fr 2fr 2fr 3fr 3fr 1fr;
                grid-template-rows: 1fr;
                grid-template-areas: "f1 f2 f3 f4 f5 f6 f7";

                > .currency-column {
                    text-align: center;
                }

                > .title-column {
                    text-align: left;
                    font-weight: 600;
                    padding-left: 0;
                }

                &:last-child {
                    height: 4.07rem;

                    .big {
                        line-height: 43px;
                    }

                    .currency-column {
                        line-height: 37px;
                    }
                }
            }

            &[data-is-discount-hidden="true"] .row:not(.wide-row) {
                grid-template-columns: 2fr 2fr 27% 27% 23.1% 23px;
                grid-template-rows: 1fr;
                grid-template-areas: "f1 f2 f4 f5 f6 f7";
                grid-gap: 1.2rem;
            }

            > .title-row {
                font-weight: bold;
                text-align: right;
                letter-spacing: .5px;

                > :last-child {
                    overflow: visible;
                    white-space: nowrap;
                    display: flex;
                    flex-direction: row-reverse;
                }
            }

            > .data-row {
                text-align: right;
                background-color: white;

                &:last-child {
                    border-top: 1px solid rgb(173, 172, 183);

                    @media print {
                        border-top: 1px solid black;
                    }
                }
            }

            > .separator-row {
                margin-top: 30px;
            }

            > .wide-row {
                background-color: white;
                grid-template-columns: 3fr 2fr 3fr 3fr 3fr 1fr !important;
                grid-template-areas: "f1 f1 f1 f2 f2 f3";
                line-height: 17px;
                grid-gap: 0;
                text-align: right;

                > .big {
                    font-size: 1.86rem;
                    font-weight: 600;
                }
            }
        }
    }

    .invoice-preview-summary-inner {
        grid-area: inner;

        > .row {
            margin-top: 6px;
            display: grid;
            grid-template-columns: 2fr 2fr 2fr 2fr 3fr 3fr 1fr;
            grid-template-rows: 1fr;
            grid-template-areas: "f1 f2 f3 f4 f5 f6 f7";

            > .currency-column {
                text-align: right;
            }

            > .title-column {
                text-align: left;
                font-weight: 600;
            }

            &:last-child {
                height: 43px;

                .big {
                    line-height: 43px;
                }

                .currency-column {
                    line-height: unset !important;
                }
            }
        }

        &[data-is-discount-hidden="true"] .row:not(.wide-row) {
            grid-template-columns: 2fr 2fr 2fr 3fr 3fr 1fr;
            grid-template-rows: 1fr;
            grid-template-areas: "f1 f2 f4 f5 f6 f7";
            grid-gap: 13px;
        }

        > .title-row {
            font-weight: bold;
            text-align: right;
            letter-spacing: .5px;
        }

        > .data-row {
            text-align: right;
            background-color: white;
        }

        > .separator-row {
            margin-top: 30px;
        }

        > .wide-row {
            background-color: white;
            grid-template-columns: 2fr 2fr 2fr 2fr 3fr 3fr 1fr !important;;
            grid-template-areas: "f1 f1 f1 f2 f2 f2 f3";
            line-height: 34px;
            text-align: right;

            > .big {
                font-size: 1.86em;
                font-weight: 600;
            }
        }
    }

    .header {
        font-size: 1.5em;
        font-weight: 600;
        padding: 53px 12px 31px;
        padding-top: 5px;
        padding-bottom: 0;
        border-bottom: 1px solid rgba(#18152B, .31);
        display: grid;
        grid-template-columns: minmax(34%, 56%) auto auto auto;
        grid-gap: 10px;

        @media print {
            padding: 8px 0 5px;
            border-bottom: 1px solid black;
        }

        .fv-number {
            > div {
                font-size: .8em;
                margin-top: 2px;
            }
        }

        .cross {
            position: absolute;
            top: 2px;
            left: 12px;
            font-size: 27px;
            line-height: 1;
            cursor: pointer;
            transition: opacity .3s;
            opacity: 1;
            font-weight: 300;

            @media print {
                display: none;
            }
        }

        [data-component="button"] {
            position: absolute;
            top: 2px;
            right: 25px;
            font-size: 1rem;

            svg {
                width: 11px;
                height: auto;
            }

            @media print {
                display: none;
            }
        }
    }

    .upper-fields {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 7px;
        padding: 32px 12px 7px;
        padding-top: 5px;
        padding-bottom: 0;
        letter-spacing: 1px;

        @media print {
            padding: 6px 0;
        }
    }

    .enums {
        padding: 0 12px 18px;
        padding-bottom: 7px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 7px;

        > div {
            flex: 1;
        }

        @media print {
            padding: 0 0 18px;
        }
    }

    .middle-fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5px .6em;
        padding: 11px 12px 22px;
        padding-bottom: 5px;
        padding-top: 0;
        letter-spacing: 1px;

        &[data-has-receiver="true"] {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media print {
            padding: 3px 0;
            grid-gap: 0 8px;
        }

        .with-border {
            border-bottom: 1px solid rgb(173, 172, 183);
            margin-bottom: 3px;

            @media print {
                border-bottom: 1px solid black;
                padding-bottom: 1px;
                margin-bottom: 0;
            }
        }

        > div {
            width: 80%;
        }
    }

    .data {
        margin-top: 5px;
        line-height: 15px;
        word-break: break-word;

        @media print {
            line-height: 15px;
            padding: 5px 0;
        }
    }

    .table {
        &-header {
            grid-template-columns: 25px auto 5% 30px 10% 10% 34px 10% 17%;
            background-color: #F8F7F9;
            font-weight: 600;
            display: grid;
            align-items: center;
            padding-left: 12px;
            border: 1px solid rgb(173, 172, 183);

            @media print {
                grid-template-columns: 40px auto 5% 49px 9.5% 9.5% 47px 8.5% 12%;
                padding: 4px 0;
                margin-bottom: 0;
                border: 1px solid black;
            }

            div {
                padding: 0;
                text-align: right;

                @media print {
                    padding: 0 2px;
                }

                &:first-child {
                    @media print {
                        text-align: right;
                        padding-left: 5px;
                    }
                }

                &:last-child {
                    padding-right: 11px;
                }

                &:nth-of-type(2) {
                    text-align: left;
                }
            }

            > :first-child {
                padding-right: 5px;

                @media print {
                    padding-right: 9px;
                }
            }
        }

        &-body {
            padding-top: 0;

            &:not(:empty) {
                border: 1px solid rgb(173, 172, 183);
                border-top: none;

                @media print {
                    border: 1px solid black;
                    border-top: none;
                }
            }

            .row {
                display: grid;
                margin-bottom: 0;
                align-items: center;
                grid-template-columns: 25px auto 5% 30px 10% 10% 34px 10% 17%;
                padding-left: 12px;

                @media print {
                    height: auto;
                    align-items: flex-start;
                    grid-template-columns: 40px auto 5% 49px 9.5% 9.5% 47px 8.5% 12%;
                    padding: 2px 0;
                }

                .cell {
                    padding: 2px 0;
                    text-align: right;
                    line-height: 14px;
                    word-break: break-all;

                    @media print {
                        padding: 2px;
                        height: auto;
                        line-height: 16px;
                        word-break: break-word;
                        white-space: normal;
                    }

                    &:first-child {
                        @media print {
                            padding-left: 5px;
                        }
                    }

                    &:last-child {
                        padding: 2px 11px 2px 0;

                        @media print {
                            padding: 2px 11px 2px 2px;
                        }
                    }

                    &:nth-of-type(2) {
                        text-align: left;
                        word-break: break-word;
                        text-overflow: ellipsis;

                        @media print {
                            white-space: normal;
                            height: auto;
                            max-height: 100%;
                        }
                    }
                }

                > :first-child {
                    padding-right: 5px;

                    @media print {
                        padding-right: 9px;
                    }
                }
            }
        }
    }

    .summary {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: 1px 33px;
        padding: 0 12px 16px;
        margin-top: 10px;
        padding-bottom: 5px;

        @media print {
            padding: 8px 0 6px;
        }

        .bigger {
            font-size: 1.5em;
            font-weight: 600;
        }
    }

    .payment-info {
        display: grid;
        grid-template-columns: repeat(5, auto);
        grid-gap: 11px;
        padding: 3px 12px;
        letter-spacing: 1px;

        @media print {
            padding: 10px 0 13px;
        }
    }

    .under-summary {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 14px;
        padding: 16px 12px;
        padding-top: 5px;
        border-top: 1px solid rgba(#18152B, .31);

        @media print {
            padding: 13px 0 0;
            border-top: 1px solid black;
        }
    }

    &[data-is-fullscreen="true"] {
        width: 100%;
        min-height: 100vh;
    }

    &[data-hide-jpk="true"] {
        .enums {
            visibility: hidden;
            height: 0;
            padding: 0 !important;

        }
    }

    @media print {
        width: 100%;
        height: auto;
        min-height: auto;
        margin: 0;
    }
}

body > [data-component="preview"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 2px;
    z-index: 99999999;
}