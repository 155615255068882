@import "style-base";

.dots-placeholder {
    background-color: transparent !important;
}

.confirm-delete {
    > .modal-content {
        width: 500px !important;

        > .modal-footer {
            display: unset !important;
        }
    }
}

.table-text {
    padding: .7rem;
    line-height: 1.3rem;
    letter-spacing: .07rem;
    word-break: break-word;
    display: flex;
}

.bool-cell {
    display: flex;
    align-items: center;
    justify-content: center;

    &[data-is-preview="true"] {
        pointer-events: none;
    }

    > [data-input-type="checkbox"] {
        opacity: 1 !important;
        transform: none !important;
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        > .checkbox-input {
            width: unset;
            position: unset;
            padding: 0;

            > .check {
                position: unset;
            }
        }
    }
}

.table-page::-webkit-scrollbar {
    width: 0;
}

.table-page {
    overflow-x: hidden;
    padding: 1.2rem 2.14rem 1.07rem 2.86rem;
    padding-bottom: 100px;
    flex: 1;
    scrollbar-width: none;

    .multiple-change-modal {
        > .modal-content {
            width: 500px !important;
        }

        .multiple-change {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;
            padding: 16px 24px;

            > .form-entry {
                width: 48%;
            }
        }
    }

    .quick-filters {
        margin-left: 15px;
        width: fit-content;
        position: relative;
        max-height: 400px;
        opacity: 1;
        transition: opacity .2s;
        transition-property: max-height, opacity;

        &[data-is-collapsed="true"] {
            max-height: 0;
            opacity: 0;
        }

        .picker {
            width: 200px;
        }

        > .filters {
            background-color: transparent;
            padding: 0;
            min-height: unset;
            position: unset;
            width: unset;

            .manual-input > input {
                height: 2.8rem;
            }

            .date-range-picker label {
                height: 2.8rem;
            }

            .filter-cell {
                padding: 0 2px;
                margin-bottom: 0;
                margin-right: 20px;
                position: relative;
                align-items: center;

                .miltiple-select {
                    background-color: white;
                }

                > .remove {
                    @extend .clickable-solid;
                    padding: 0 7px;
                    position: absolute;
                    top: 3px;
                    left: 5px;
                    font-size: 19px;
                }

                > .title {
                    font-size: 0.97rem;
                    margin-left: 4px;
                    margin-right: 10px;
                    position: relative;

                    &:after {
                        content: ':';
                    }
                }

                .date-range {
                    > label {
                        width: 120px;
                        height: 2.5rem;
                        border: none;
                    }

                    > .dash {
                        width: 19px;
                    }
                }
            }

            > .buttons {
                display: none;
            }

            > .cross {
                display: none;
            }

            > .cells {
                display: flex;

                [data-input-type="date"] {
                    width: 150px;
                }

                .manual {
                    > .dash {
                        width: 30px;
                        text-align: center;
                    }

                    .manual-input {
                        input {
                            width: 80px;
                            padding: 0 9px;
                        }
                    }
                }

                .range-picker {
                    margin-top: 0;

                    > .bar, > .full-bar, > .mark {
                        display: none;
                    }

                    > .manual {
                        padding-top: 0;
                    }
                }
            }
        }
    }

    [data-component="modal"] {
        .modal-content {
            width: 79.6rem;
        }
    }

    &[data-has-accessory="true"] {
        .row, .table-header {
            // padding-left: 30px;
        }
    }

    &[data-always-visible-filters="true"] {
        margin-right: 43rem;

        .btn--filters {
            visibility: hidden;
        }

        .filters {
            position: fixed;
            top: 0;
            box-shadow: 0 0 20px rgba(black, .2);
            bottom: 0;
            overflow: scroll;

            .cross {
                visibility: hidden;
            }

            .with-bg {
                display: none;
            }
        }
    }

    > .header {
        display: flex;
        flex-direction: row;
        padding: 0;
        align-items: center;
        padding-bottom: 1.6rem;

        > .search {
            width: 18.9rem;
            display: block;
            margin-right: 2.5rem;

            input {
                width: 100%;
                border-radius: 7px;
                padding: .7rem 1.07rem;
                height: 2.5rem;

                &::placeholder {
                    color: #191717;
                }
            }
        }

        [data-component="button"] {
            margin-right: 2.5rem;

            .icon svg {
                width: 1.4rem;
                height: auto;
            }

            [data-icon-name="filter"] {
                svg {
                    width: 1rem;
                }
            }

            .title {
                letter-spacing: .5px;
                margin-left: .75rem;
            }
        }
    }

    .row {
        margin-bottom: .5rem;
        display: grid;
        grid-template-rows: 1fr;
        align-items: center;
        grid-template-columns: var(--layout);
        grid-template-rows: 20px;

        > :first-child {
            padding-left: 2.4rem;
        }
    }

    .table-body {
        padding-bottom: 30px;
    }

    .table-header {
        display: grid;
        grid-template-columns: var(--layout);
        grid-template-rows: 1fr;

        > div {
            grid-area: unset !important;
        }
    }

    > .footer {
        position: absolute;
        right: 3.3rem;
        bottom: 0;
    }
}
