.help {
    background-color: white;
    padding: 3.57rem 3.57rem 1.79rem;
    width: 66rem;
    margin: auto;
    overflow: auto;
    position: relative;

    &-container {
        position: fixed;
        z-index: 103;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, .3);
    }

    > .form-row {
        padding-left: 2.14rem;
        padding-right: 2.14rem;
        padding-top: 2.68rem;
        background-color: #F8F7FC;
        margin-bottom: 0;
    }

    > .close {
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
        cursor: pointer;
        font-size: 2rem;
    }

    .admin-info {
        font-style: normal;
        text-transform: none;
        font-weight: normal;
        letter-spacing: .5px;
        font-size: 1.05rem;
        padding-bottom: 1.5rem;
    }

    .bold-text {
        font-weight: bold;
        font-size: 1.3rem;
        margin-bottom: 1rem;
    }

    .form {
        background-color: #F8F7FC;
        padding: 2.68rem 2.14rem;
        margin-bottom: 2rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "f1 f1" "f2 f2" "f3 .";
        grid-gap: 1rem;
        font-size: 1rem;
    }

    .button-container {
        display: flex;
        justify-content: flex-end;

        [data-component="button"] .title {
            font-size: 1rem;
        }
    }

    .textarea-label {
        grid-area: f2;

        .title {
            font-weight: 600;
            text-align: left;
            margin-bottom: 3px;
            transition: color .3s;
            font-size: 1rem;
            letter-spacing: .5px;
        }

        textarea {
            resize: none;
            box-sizing: border-box;
            width: 100%;
            border-radius: 7px;
            padding: 1rem 1.4rem;
            font-size: 1.04rem;
            border: 1px solid var(--border-default-color);
            transition: border-color .3s;
            outline: none;
            background-color: white;
            font-family: "Poppins", sans-serif;
            overflow: auto;
            text-overflow: ellipsis;
            height: 8rem;
        }
    }
}

.help-button {
    position: fixed;
    right: 0;
    bottom: 6%;
    padding: .8rem;
    background: linear-gradient(142deg, #47CE78 0%, #39BD99 100%);
    margin: 0;
    border-radius: 7px 0 0 7px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, .08);
    z-index: 5;
    background-size: 200% 200%;
    background-image: linear-gradient(142deg, #4DD57E 0%, #38B794 44%, #4DD57E 100%);
    transition: .4s;

    svg {
        width: 1.9rem;
        height: 1.9rem;
    }

    &:hover {
        background-position: right center;
    }
}
