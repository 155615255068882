@import '../../../../node_modules/react-big-calendar/lib/sass/styles.scss';
@import "style-base";

$gray: #F6F4F6;
$green: #47CE78;

.calendar {
    padding: 50px;
    padding-top: 20px;

    > [role="button"] {
        margin-bottom: 20px;
    }

    .week-header {
        font-weight: 500;
        color: #3C4126;

        > :first-child {
            font-size: 15px;
            margin-bottom: -8px;
        }
        
        > :last-child {
            font-size: 30px;
        }
    }

    > .rbc-calendar {
        background-color: white;
        border-radius: 6px;
        box-shadow: 0 0 16px 1px rgba(black, .16);

        > .rbc-toolbar {
            background-color: $gray;
            margin: 0;
            padding: 33px;

            > :first-child {
                > button {
                    @extend .clickable-solid;
                    background: url("/assets/icons/calendar_arrow.svg") no-repeat center center;
                    color: transparent;
                    border: none;
                    padding: 0;
                    font-size: 0px;
                    height: 30px;
                    width: 30px;
                }

                > :nth-child(1) {
                    display: none;
                }

                > :nth-child(3) {
                    transform: scaleX(-1);
                }
            }

            > .rbc-toolbar-label {
                text-align: left;
            }

            > :last-child {
                > button {
                    @extend .clickable-solid;
                    border: none;
                    height: 30px;
                    border-radius: 15px;
                    padding-left: 15px;
                    padding-right: 15px;
                    margin-left: 9px;
                }

                > .rbc-active {
                    color: white;
                    background-color: $green;
                    pointer-events: none;
                }
            }
        }

        > .rbc-month-view {
            > .rbc-month-header {
                background-color: $gray;
            }

            .rbc-header {
                font-size: 16px;
                font-weight: normal;
            }
        }

        > .rbc-time-view {
            border: none;
            background-color: $gray;

            > .rbc-time-header {
                > .rbc-time-header-gutter {
                    padding: 0;
                    display: flex;
                    flex-direction: column-reverse;

                    &:before {
                        content: "";
                        background-color: white;
                        width: 100%;
                        height: 40px;
                    }
                }
            }

            .rbc-today {
                background-color: transparent;
            }

            > .rbc-time-content {
                background-color: white;
            }

            .rbc-time-header-cell {
                > .rbc-header {
                    border-bottom: none;
                }

                > .rbc-today {
                    .week-header {
                        color: $green;
                    }
                }
            }

            .rbc-allday-cell {
                background-color: white;
            }

            .rbc-allday-cell {
                height: auto;
            }
        }
    }
}