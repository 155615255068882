.avatar-picker {
    height: 9.5rem;
    width: 9.5rem;
    background: url("/assets/icons/avatar_placeholder.svg") no-repeat center center;
    background-size: 100% 100%;
    border-radius: 50%;

    > .spinner {
        position: absolute;
        left: calc(50% - 2.86rem);
        top: calc(50% - 2.86rem);
    }

    > .overlay {
        background: url("/assets/icons/avatar_overlay.svg") no-repeat center center;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity .2s;
        pointer-events: none;
        cursor: pointer;
        color: white;
        font-size: .95rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 2.86rem;
        border-radius: 50%;
    }

    &:hover {
        > .overlay {
            pointer-events: all;
            opacity: 1;
        }
    }
}
