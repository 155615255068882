@import "style-base";

.bubble {
    > .autofill {
        height: 100%;
    }
}

.autofill {
    position: relative;
    
    input {
        border: none;
        height: 100%;
    }

    .picker-body {
        position: absolute;
        background-color: white;
        left: 0;
        right: 0;
        box-shadow: 0 0 16px 1px rgba(black, .16);
        z-index: 10;

        &[data-is-placeholder="true"] {
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        > .element-row {
            @extend .clickable-solid;
            padding: .5rem 1.43rem;
            font-size: 1rem;
            transition: background-color .3s;

            &:hover {
                background-color: #47CE78;
            }
        }
    }
}
