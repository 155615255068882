.form-section {
    > .title {
        width: 100%;
        border-bottom: 1px solid rgba(103, 94, 160, 0.31);
        font-size: 1.14rem;
        font-weight: 600;
        margin-bottom: 30px;
    }

    > .entries {
        --layout: "";
        display: grid;
        grid-template-columns: repeat(24, 1fr);
        grid-template-areas: var(--layout);
        grid-gap: 5px;
        column-gap: 12px;
        row-gap: 18px;

        > :nth-child(1) { grid-area: f1 }
        > :nth-child(2) { grid-area: f2 }
        > :nth-child(3) { grid-area: f3 }
        > :nth-child(4) { grid-area: f4 }
        > :nth-child(5) { grid-area: f5 }
        > :nth-child(6) { grid-area: f6 }
        > :nth-child(7) { grid-area: f7 }
        > :nth-child(8) { grid-area: f8 }
        > :nth-child(9) { grid-area: f9 }
        > :nth-child(10) { grid-area: f10 }
        > :nth-child(11) { grid-area: f11 }
        > :nth-child(12) { grid-area: f12 }
        > :nth-child(13) { grid-area: f13 }
        > :nth-child(14) { grid-area: f14 }
        > :nth-child(15) { grid-area: f15 }
        > :nth-child(16) { grid-area: f16 }
    }
}