@import "mixins", "vars";

.miltiple-select {
    position: relative;
    height: auto;
    min-height: 3.5rem;
    border-radius: 7px;
    border: 1px solid #E7E5E9;

    &:after {
        content: "";
        border: solid $green--darker;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        position: absolute;
        right: 23px;
        top: calc(50% - 4px);
        opacity: 1;
        transition: opacity .3s;
        transform: rotate(45deg);
        pointer-events: none;
    }

    > .dismiss-overlay {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 99;
    }

    > .face {
        width: 100%;
        height: auto;
        min-height: 3.5rem;
        display: flex;
        align-items: center;
        padding: 0 3.5rem 0 2rem;
        font-size: 1rem;
        font-weight: 300;
    }

    > .picker {
        position: absolute;
        top: 100%;
        min-width: 200px;
        left: 0;
        right: 0;
        z-index: 100;
        font-size: 1rem;
        font-weight: 300;
        background-color: white;
        box-shadow: 0 0 16px 1px rgba(black, .16);
        max-height: 50vh;
        overflow: auto;
        --zeroTransform: scale(1);
        transform: var(--zeroTransform);

        @include animation-keyframes {
            from {
                opacity: 0;
                transform: var(--zeroTransform) translateY(-20px);
            }
    
            to {
                opacity: 1;
                transform: var(--zeroTransform);
            }
        }
        animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
        animation-duration: .3s;

        > .item {
            margin: 8px 0;
            display: flex;
            align-items: center;
            padding-left: 33px;

            > label {
                width: 100%;
            }
        }

        &[data-is-drop-up="true"] {
            top: unset;
            bottom: 0;
        }
    }
}