@import "_vars";

.autocomplete {
    max-height: 150px !important;
    background-color: white;
    overflow-y: auto;
    box-shadow: 0 0 20px rgba(black, .2);

    > div {
        padding: .5rem 1.43rem;
        font-size: 1rem;
        transition: background-color .3s;
        background-color: transparent;

        &.selected {
            background-color: $green;
        }
    }
}

[data-component="input"] {
    display: flex;
    flex-direction: column;
    position: relative;

    .percent-sign {
        position: absolute;
        right: 4px;
        top: 50%;
        transform: translateY(1px);
    }

    &[data-is-percent="true"] {
        text-align: right;
        padding-right: 14px !important;
    }

    &[data-error], [data-error] {
        .title {
            color: #ED2E4E;
        }

        .input-outer:after {
            border-color: #ED2E4E !important;
        }

        &:before {
            position: absolute;
            z-index: 4;
            bottom: 100%;
            left: 50%;
            padding: .5em 1em;
            white-space: nowrap;
            content: attr(data-error);
            color: white;
            font-size: .8em;
            line-height: 1.7em;
            background-color: #ED2E4E;
            opacity: 0;
            transform: translate(-50%, 0);
            border-radius: 4px;
            box-shadow: 0 0 20px rgba(black, .2);
            transition: opacity .2s, transform .2s ease-out;
            pointer-events: none;
        }

        &:after {
            position: absolute;
            z-index: 3;
            display: block;
            bottom: 100%;
            left: 50%;
            width: 10px;
            height: 10px;
            margin-bottom: -4px;
            content: "";
            background-color: #ED2E4E;
            opacity: 0;
            transform: translate(-50%, 0) rotate(45deg);
            transition: opacity .2s, transform .2s ease-out;
            pointer-events: none;
        }

        &:hover, &:focus {
            &:before {
                opacity: 1;
                transform: translate(-50%, -10px);
            }

            &:after {
                opacity: 1;
                transform: translate(-50%, -10px) rotate(45deg);
            }
        }

        .input {
            border-color: #ED2E4E;
        }
    }

    > .title {
        font-weight: 600;
        text-align: left;
        margin-bottom: 3px;
        transition: color .3s;
        font-size: 1rem;
        letter-spacing: .5px;
        height: 17px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    > .title, > .input-outer {
        width: 100%;
    }

    .input {
        --border-default-color: transparent;
        box-sizing: border-box;
        width: 100%;
        height: 2.7rem;
        border-radius: 7px;
        padding: 0 .8rem;
        font-size: 1.04rem;
        border: 1px solid var(--border-default-color);
        transition: border-color .3s;
        outline: none;
        background-color: white;
        font-family: "Poppins", sans-serif;
        overflow: hidden;
        text-overflow: ellipsis;

        &[read-only="true"] {
            color: rgba(#191717, .5);
        }
    }

    textarea.input {
        resize: none;
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    &[data-input-type="select"] {

        &[data-is-capsuled="false"],
        .input-outer {
            position: relative;
        }

        &[data-is-capsuled="false"]:after,
        &[data-is-capsuled="true"] .input-outer:after {
            display: block;
            content: "";
            position: absolute;
            top: 50%;
            margin-top: -8px;
            right: 1.43rem;
            height: .57rem;
            width: .57rem;
            border: 3px solid $green;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
            opacity: 1;
            transition: opacity .3s;
            pointer-events: none;
        }

        &[data-is-focused="true"][data-is-capsuled="false"]:after,
        &[data-is-focused="true"] .input-outer:after {
            opacity: 0;
        }

        .input {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 0 2.8rem 0 .8rem;
        }
    }

    &[data-input-type="date"] {

        .input-outer {
            position: relative;
        }

        .input-button {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 4rem;
            background-image: url("/assets/icons/calendar.svg");
            background-size: 1.69rem 1.54rem;
            background-position: center center;
            background-repeat: no-repeat;
            border-radius: 0 7px 7px 0;
            border: 0 solid transparent;
            transition: opacity .3s, border-color .3s;
            cursor: pointer;
            pointer-events: none;
        }

        &:hover .input-button {
            opacity: .9;
        }
    }

    &[data-input-type="checkbox"] {
        flex-direction: row-reverse;
        margin-bottom: 5px;
        align-items: center;

        .checkbox-input {
            cursor: pointer;
            width: 100%;
            position: relative;
            padding-left: 3rem;

            .check {
                width: 1.5rem;
                height: 1.45rem;
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(57, 189, 146, .34);
                border-radius: .4rem;

                &:after {
                    content: "";
                    border: .2rem solid white;
                    border-width: 0 .2rem .2rem 0;
                    height: .9rem;
                    width: .45rem;
                    transform: rotate(41deg);
                    position: absolute;
                    top: .05rem;
                    right: .45rem;
                    opacity: 0;
                    transition: 100ms opacity ease-in-out;
                }
            }

            [type="checkbox"] {
                display: none;
                opacity: 0;
            }

            [type="checkbox"]:checked + .check {
                background: #39BD92;

                &:after {
                    opacity: 1;
                }
            }
        }

        .input {
            height: 1.43rem;
            width: 1.5rem;
            margin-right: 10px;
            background-color: #39BD92;
        }

        > .input-outer {
            width: auto;
            margin-bottom: 0;
        }

        > .title {
            flex: 1;
            width: auto;
            font-weight: 600;
            font-size: 1rem;
            margin-bottom: 0;
            letter-spacing: .5px;
        }
    }
}

.price-input-element {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > .title {
        width: auto;
        font-weight: 600;
        font-size: 1rem;
        margin-bottom: 0;
        letter-spacing: .5px;
    }

    > input {
        background-color: white;
        width: 100%;
        outline: none;
        border: none;
        height: 2.7rem;
        border-radius: 7px;
        padding: 0 .8rem;
        font-size: 1.04rem;
    }

    &[data-is-invalid="true"] {
        > input {
            border: 1px solid red;
        }

        > .title {
            color: red;
        }
    }
}
