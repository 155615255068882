@import "style-base";

.cost-invoices-table {
    .modal-content {
        width: 100% !important;

        > .modal-footer {
            display: none;
        }

        .cost-invoice-details {
            height: unset;
        }
    }

    .confirm-delete {
        > .modal-content {
            > .modal-footer {
                display: unset;
            }
        }
    }

    .positive-button {
        display: none;
    }
    
    .lock-accessory {
        @extend .clickable-solid;
        width: 40px;
        height: 100%;
        position: absolute;
        
        &:hover {
            > .lock-ear {
                transform: translate(-50%, -50%) translateY(-5px);
            }
        }

        > .lock-body {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) translateY(4px);
            background-image: url("/assets/icons/lock_body.svg");
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            width: 15px;
            height: 15px;
        }

        > .lock-ear {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-image: url("/assets/icons/lock_ear.svg");
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            width: 15px;
            height: 15px;
            transition: transform .2s;
        }
    }

    .single-modal {
        .cross {
            margin-right: 30px;
        }

        .modal-header {
            padding-left: 7px;

            > .cross {
                top: 0;
            }
        }

        .modal-footer {
            padding-right: 30px;
        }

        > .modal-content {
            width: 99vw !important;
            padding: 0;;
    
            > .modal-body {
                > .add-expense {
                    height: calc(100vh - 200px);
                }
            }
        }
    }
}