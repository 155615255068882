#app-body-invoice {
    .invoice-preview {
        max-width: 1250px;
        margin: auto;
        width: 73%;
        min-width: 900px;

        @media print {
            width: 100%;
            max-width: auto;
            min-width: auto;
            margin: 0;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    .preview {
        background: white;
        min-height: 100vh;
        width: 100%;
        margin-left: auto;
        position: relative;

        .title {
            font-weight: 600;
        }

        .content {
            background-color: white;
        }

        .header {
            font-size: 1.29rem;
            font-weight: 600;
            border-bottom: 1px solid rgba(#18152B, .31);
            padding: 3.5rem 0 1.8rem;
        }

        .upper-fields {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: .6rem;
            padding: 2.25rem;
            letter-spacing: 1px;
        }

        .middle-fields {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: .6rem;
            padding: 1rem 2.14rem 2rem;
            letter-spacing: 1px;
        }

        .data {
            margin-top: 5px;
            line-height: 1.4rem;
        }

        .table {
            &-header {
                background-color: #F8F7F9;
                font-weight: 600;
                display: grid;
                grid-template-columns: 8% 15.5% 8% 8% 16% 9% 7% 7% 1fr;
                grid-gap: .7rem;
                margin-bottom: .5rem;

                @media print {
                    grid-template-columns: 5% 18.5% 8% 8% 16% 9% 7% 7% 1fr;
                }

                div {
                    padding: 1.5rem .7rem .8rem;
                    text-align: right;

                    &:last-child {
                        padding-right: 2rem;
                    }

                    &:nth-of-type(2) {
                        text-align: left;
                    }
                }
            }

            &-body {
                .row {
                    display: grid;
                    grid-template-columns: 8% 15.5% 8% 8% 16% 9% 7% 7% 1fr;
                    grid-gap: .7rem;
                    margin-bottom: 0;

                    @media print {
                        grid-template-columns: 5% 18.5% 8% 8% 16% 9% 7% 7% 1fr;
                    }

                    .cell {
                        padding: .2rem .7rem;
                        text-align: right;

                        &:last-child {
                            padding: .2rem 2rem .7rem 0;
                        }

                        &:nth-of-type(2) {
                            text-align: left;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                    }
                }
            }
        }

        .summary {
            display: grid;
            grid-template-columns: 1fr 3fr;
            grid-gap: .7rem 2rem;
            padding: 5.2rem 2.14rem 4.2rem;

            .bigger {
                font-size: 1.86rem;
                font-weight: 600;
            }
        }

        .under-summary {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1.3rem;
            padding: 3rem 2.14rem;
            border-top: 1px solid rgba(#18152B, .31);

            div:first-child, .comments {
                grid-column: span 2;
            }
        }
    }
}
