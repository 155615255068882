.dashboard-list {
    font-size: 12px;
    color: #3C4126;
    --list-layout: "";

    > .dashboard-list-row, > .labels{
        position: relative;
        display: grid;
        grid-gap: .7rem;
        grid-template-rows: 1fr;
        grid-template-columns: var(--list-layout);
        padding: 0 8px;

        > .status-cell {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            > .status {
                font-size: 8px;
                color: #808481;
            }
        }
    }

    > .labels {
        font-size: 11px;
        color: #BEBFBA;
    }

    > .dashboard-list-row {
        height: 55px;
        position: relative;
        background-color: #F6F4F6;
        margin-top: 8px;
        border-radius: 5px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &:before {
            content: "";
            position: absolute;
            left: -18px;
            top: 0;
            bottom: 0;
            width: 18px;
            border-radius: 5px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &[data-appearence="regular"] {
            &:before {
                background-color: #BEBFBA;
            }
        }

        &[data-appearence="soon"] {
            &:before {
                background-color: #57B67E;
            }
        }

        > div {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
}
