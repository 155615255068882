@media print {
    @page {
        margin-top: 5mm;
        margin-bottom: 4mm;
    }

    @page :footer {
        display: none;
    }

    @page :header {
        display: none;
    }

    thead {
        display: table-header-group;
    }

    tfoot {
        display: table-footer-group;
        height: calc(27px + 4mm);
    }

    .help-button {
        display: none;
    }

    .invoice-table-container {
        width: 100%;
        border-spacing: 0;

        &.with-padding {
            padding-top: 5px;
        }
    }

    // html, body {
    //     height:100vh;
    //     margin: 0 !important;
    //     padding: 0 !important;
    //     overflow: hidden;
    //   }
}

@media screen {
    .invoice-table-container,
    .invoice-table-container tbody {
        display: block;
    }

    .only-for-print {
        display: none !important;
    }
}

.page-header,
.page-footer {
    display: flex;
    font-size: 1rem;

    a {
        font-weight: 600;
        color: #3ab992;
        margin: 0 10px;
    }
}

.page-header {
    top: 10px;
    height: 35px;
    align-items: flex-end;
    padding-bottom: 6px;
    border-bottom: 1px solid black;
    justify-content: flex-start;
    flex-direction: row;
    z-index: 111111;
    background: white;

    a {
        margin: 0 12px;
        letter-spacing: .5px;
        font-size: 1rem;
    }

    svg {
        width: auto;
        height: 16px;
    }

    &-text {
        display: flex;
        flex-direction: column;
        line-height: 15px;
        margin-right: 15px;
        position: relative;
    }
}

.page-footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    border-top: 1px solid black;

    a {
        letter-spacing: .5px;
    }
}

.page-footer,
.page-footer-space {
    height: 26px;
    z-index: 111111;
    background: white;
}

#preview-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    transition: opacity .3s;
    opacity: .3;
    z-index: 5000;
    pointer-events: all;

    &[data-is-hidden="true"] {
        pointer-events: none;
        opacity: 0;
    }

    @media print {
        display: none;
    }
}

[data-component="preview"] {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s;
    z-index: 7500;
    max-width: 100%;
    display: block;
    overflow: auto;

    &[data-is-visible="true"] {
        pointer-events: all;
        opacity: 1;
    }

    > .preview-content {
        > .preview-body {
            > .invoice-post-creator__nav {
                right: -127px;
                left: unset;
                width: unset;

                > div {
                    transition: transform 0.2s ease-out;

                    &:hover {
                        transform: translateX(-100px);
                    }
                }
            }
        }
    }
}

@media print {
    .notyf {
        display: none;
    }


    [data-component="preview"] {
        transition: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        display: block;
        overflow: unset;
        opacity: 1;
        z-index: 1000;
        background: white;
    }
}
