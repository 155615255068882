@import "style-base";

.dashboard-summary {
    display: flex;

    > .card {
        width: 350px;
        margin-right: 10px;
        border-radius: 8px;
        border: 1px solid rgba(#CBCCC7, .25);
        color: #3C4126;

        > .title {
            font-size: 10px;
            height: 34px;
            padding-left: 12px;
            color: white;
            background-color: #57B67E;
            border-radius: 8px;
            display: flex;
            align-items: center;
        }

        > .content {
            padding: 10px;

            > .prices {
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;

                > div {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }

                > .main {
                    > .price {
                        font-size: 18px;
                        font-weight: bold;
                    }
                }

                > .secondary {
                    > .price {
                        font-size: 12px;
                    }
                }

                .description {
                    color: #BEBFBA;
                    font-size: 11px;
                    font-weight: 500;
                }
            }

            > .add-buttons {
                display: flex;
                margin-bottom: 15px;

                > .add-button {
                    @extend .clickable-solid;
                    font-size: 11px;
                    font-weight: bolder;
                    padding: 5px 12px;
                    border-radius: 7px;
                    background-color: #57B67E;
                    color: white;
                    margin-right: 7px;
                }
            }

            > .supplementary-buttons {
                display: flex;

                > .supplementary-button {
                    @extend .clickable-solid;
                    position: relative;
                    color: #BEBFBA;
                    font-size: 11px;
                    font-weight: 500;
                    margin-right: 15px;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 4px;
                        bottom: 4px;
                        right: -8px;
                        width: 1px;
                        background-color: #BEBFBA;
                    }

                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
