.expense-products {
    > [data-component="button"] {
        transform: scale(0.9);
    }

    .show-more {
        right: unset !important;
        left: -20.2px;
        transform: scale(0.8);
        top: -6px;
    }

    .select-box:after {
        right: -2px;
    }

    .form-entry > .input-element > .name {
        font-size: 9px;
        margin-bottom: -4px;
    }

    > .rows {

        .name {
            height: 0;
        }

        > :first-child {
            .name {
                height: unset;
            }

            .show-more {
                top: 6px;
            }
        }

        > div {
            &:last-child {
                margin-bottom: unset;
            }
        }

        > .product-row {
            position: relative;

            > :nth-child(2) {
                display: none;
            }

            &[data-is-expanded="true"] {
                > :nth-child(2) {
                    display: grid;
                    margin-top: -8px;
                    padding-left: 40px;

                    .name {
                        height: unset;
                    }
                }
            }
            
            > [data-component="button"] {
                position: absolute;
                bottom: 0;
                right: -25px;
            }

            > .form-row {
                margin-bottom: 8px;
            }

            input {
                font-size: 11px;
            }

            select {
                font-size: 11px;
            }

            .bubble {
                height: 20px;
            }
        }
    }
}
