.advanced-search {
    width: 50vw;
    max-height: 70vh;
    background-color: white;
    border-radius: 7px;
    box-shadow: 0 0 16px 1px rgba(black, .16);
    display: flex;
    flex-direction: column;

    > .overlay {
        position: fixed;
        left: -100vw;
        right: -100vw;
        top: -100vh;
        bottom: -100vh;
        z-index: -1;
    }

    > [data-input-type="text"] {
        position: relative;
        padding-left: 4px;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background-color: lightgray;
        }

        > .input {
            height: 44px;
        }
    }

    > .models {
        padding: 7px;
        overflow: scroll;
        flex: 1;

        > .model-row {
            position: relative;
            display: flex;
            align-items: center;
            margin: 10px 0;
            padding: 0 7px;

            &:hover {
                background-color: #47CE78;
                color: white;
                cursor: pointer;
            }

            &:after {
                content: "";
                position: absolute;
                bottom: -5px;
                left: 0;
                right: 0;
                height: 1px;
                background-color: lightgray;
            }

            > [data-input-type="checkbox"] {
                transform: translateY(-6px);
            }
        }
    }
}
