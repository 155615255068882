@import "style-base";

.allegro {
    > .button {
        @extend .clickable-solid;
    }

    .integration-form {
        padding: 2.68rem 2.14rem;
    }
}
