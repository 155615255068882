.labels-picker {
    position: absolute;
    right: 97%;
    top: 0;
    z-index: 12;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &:before {
        content: '';
        position: fixed;
        width: 100%;
        height: 100%;
        top: 41px;
        left: 50px;
        backdrop-filter: blur(4px);
        z-index: 1;
        cursor: initial;
        transition: left .2s;
    }

    .labels-picker__close {
        width: 15px;
        height: 15px;
        cursor: pointer;
        z-index: 2;
        position: relative;
        margin-bottom: 5px;
        margin-right: -1px;
        opacity: 1;
        transition: opacity .2s ease;

        &:hover {
            opacity: .85;
        }
    }

    $starting-rotation: 24deg;
    $rotation-increment: 3deg;
    $number-of-boxes: 20;
    $left-shift: 8px;

    @keyframes showing-labels {
        0% {
            transform: translateY(-6px);
            opacity: 0;
        }

        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .labels-picker-items {
        animation: showing-labels .2s ease;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}

.label-in-picker {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    z-index: 2;
    position: relative;
    transform-origin: right;
    cursor: pointer;

    &:hover {
        .text-label {
            opacity: .85;
        }
    }

    > .text-label {
        height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        margin-right: 4px;
        background-color: currentColor;
        transition: opacity .2s ease;

        .text {
            color: #FFF;
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 300;
            padding: 2px 5px;
            margin: 0;
            user-select: none;
        }
    }

    > .tag {
        width: 8px;
        height: 9px;
        border-radius: 1px 1px 0 0;
        position: relative;
        border-bottom: none;
        position: relative;
        background-color: #FFF;
        border: 1px solid currentColor;
        border-bottom: none;

        &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: -1px;
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-top: 4px solid currentColor;
            border-bottom: 0;
        }

        &:before {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 0;
            height: 0;
            border: 3px solid transparent;
            border-top: 3px solid #FFF;
            border-bottom: 0;
            z-index: 1;
        }
    }

    &[data-is-selected="true"] {
        > .tag {
            border: none;
            background-color: currentColor;

            &:after {
                left: 0;
            }

            &:before {
                display: none;
            }
        }
    }
}