@import "style-base";

.company-data {
    @extend .hideen-scrollbars;

    &--force {
        width: 61%;
        margin: 5% auto 5% 9%;
        min-width: 790px;
        overflow: unset !important;
    }

    &--profile {
        padding: 0 0 1.07rem;

        .form {
            width: 61%;
            margin: 0 2.86rem;
            min-width: 790px;
        }
    }

    > .title {
        font-size: 1.86rem;
        font-weight: 600;
        margin: 4rem 0 3rem 2.86rem;
    }

    > .form {
        position: relative;
        display: grid;
        grid-gap: .5rem 2.78rem;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 2rem;
        grid-template-areas: "f1 f1 f2 f2" "f3 f3 f4 f4" "f5 f5 f6 f7" "f8 f8 f9 f9" "f10 f10 f11 f11" ". . . f12";

        [data-component="input"], [data-component="input"] .input {
            height: 3.8rem;
        }

        label {
            height: 3.8rem;
            background-color: white;
            flex-direction: row;
            align-items: center;

            > .title {
                width: 15.38rem;
                padding-left: 1.3rem;
                margin-bottom: 0;
            }
        }
    }
}
