.invoice-post-creator {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    justify-content: center;
    background-color: white;
    display: grid;
    grid-template-columns: 15% 70% 15%;
    grid-template-areas: ". prev nav";
    overflow-x: hidden;
    overflow-y: auto;

    .close {
        position: absolute;
        right: 2rem;
        top: 2rem;
        cursor: pointer;
        font-size: 2.5rem;
        font-weight: 300;
    }

    .invoice-table-container {
        grid-area: prev;
    }

    .invoice-preview {
        width: 100%;
        max-width: 797px;
        margin: unset;
        overflow-y: auto;
        margin-left: auto;
        margin-right: auto;
        box-shadow: none;
        font-size: 1rem;
        padding: 0 40px;
        box-shadow: 0 0 10px 3px #0000001c;

        @media print {
            box-shadow: none;
            font-size: 1.43rem;
            padding: 0 2px;
            max-width: 100%;
        }

        [data-component="table"] {
            margin: 10px 0 9px;
        }

        .title-row, .data-row {
            grid-gap: 0 5px !important;
        }

        .data-row {
            margin-top: 1px !important;
        }

        .content {
            margin-top: 0;
            padding: 4px 0 0;
            line-height: 17px;
            background-color: white;
        }

        .data {
            margin-top: 0 !important;
        }

        .advance-header {
            margin: 13px 0 0;
        }

        .title {
            font-size: 1.1em;
            line-height: 14px;
        }

        .invoice-preview-summary {
            padding: 5px 10px 0;
            margin-top: 0;
            grid-template-columns: auto minmax(auto, 52%);

            > .invoice-preview-summary-inner {
                .title-row {
                    letter-spacing: 0;
                }

                .data-row {
                    &:last-child {
                        border-top: 1px solid black;
                    }
                }
            }
        }

        .header {
            padding: 46px 0 4px;
            border-bottom: 1px solid black;

            .cross {
                display: none;
            }

            [data-component="button"] {
                display: none;
            }
        }

        .upper-fields {
            padding: 9px 0 3px;
        }

        .enums {
            padding: 0 0 6px;
        }

        .middle-fields {
            padding: 4px 0 9px;
            grid-gap: 0 8px;

            .with-border {
                border-bottom: 1px solid black;
                padding-bottom: 5px;
            }

            > div {
                width: 80%;
            }
        }

        .data {
            line-height: 14px;
            padding: 2px 0;
        }

        .table {
            &-header {
                grid-template-columns: 33px auto 5% 36px 8.5% 8.5% 36px 7.5% 12%;
                padding: 3px 0;
                margin-bottom: 0;
                border: 1px solid black;

                div {
                    padding: 0 2px;

                    &:first-child {
                        text-align: right;
                        padding-left: 5px;
                    }
                }

                > :first-child {
                    padding-right: 9px;
                }
            }

            &-body {
                border: 1px solid black;
                border-top: none;
                padding-top: 0;

                .row {
                    height: auto;
                    align-items: flex-start;
                    grid-template-columns: 33px auto 5% 36px 8.5% 8.5% 36px 7.5% 12%;
                    padding: 1px 0;

                    .cell {
                        height: auto;
                        word-break: break-word;
                        white-space: normal;
                        padding: 2px;

                        &:first-child {
                            padding-left: 5px;
                        }

                        &:nth-of-type(2) {
                            white-space: normal;
                            height: auto;
                            max-height: 100%;
                        }
                    }

                    > :first-child {
                        padding-right: 9px;
                    }
                }
            }
        }

        .summary {
            padding: 10px 0 5px;
            grid-gap: 0 23px;
        }

        .payment-info {
            padding: 15px 0 12px;
        }

        .under-summary {
            padding: 11px 0 20px;
            border-top: 1px solid black;
        }
    }

    &__nav {
        grid-area: nav;
        position: fixed;
        left: calc(15% + 70%);
        height: auto;
        width: 15%;
        color: black;
        display: flex;
        flex-direction: column;
        bottom: 10%;
        z-index: 1;

        &__item {
            font-weight: 300;
            min-height: 3rem;
            font-size: 1.1rem;
            margin: .43rem 1.43rem .43rem 0;
            transition: background-color .3s;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 0 0 1.2rem;
            border-radius: 25px;
            background: linear-gradient(142deg, #47CE78 0%, #39BD99 100%);
            position: relative;
            color: white;
            width: 77%;
            max-width: 220px;
            min-width: 150px;
            box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, .15);

            &:hover {
                cursor: pointer;
                background: linear-gradient(142deg, #42C572 0%, #34B390 100%);
            }

            span {
                position: absolute;
                left: 3.7rem;
            }

            img {
                width: 1.5rem;
                height: auto;
                filter: brightness(0) invert(1);
            }
        }
    }
}
