.cost-invoice-details {
    height: 100%;

    .cost-invoice-form {
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;

        .actions {
            flex: 1;
            display: flex;
            align-items: flex-start;
            background-color: #FFF;
            padding: 16px 39px;
            justify-content: flex-end;

            [data-component="button"] {
                font-size: 11px;
                font-weight: $regular;
                min-height: 20px;
                padding: 2px 9px;
                background-color: #FFF;
                text-transform: uppercase;
                border: 1px solid $green;
                border-radius: 6px;
                cursor: pointer;
                margin-left: 12px;
                text-align: center;
                transition: all .2s ease;

                &:hover {
                    background-color: #0EC550;
                    color: #FFF;
                }
            }

            > [data-component="button"] {
                width: 100px;
            }

            > .other {
                > div {
                    margin-bottom: 5px;
                }
            }
        }
    }

    .split {
        .lhs {
            width: 40.55%;
        }

        .resize-bar {
            box-shadow: none;
        }
    }
}
