@import "style-base";

.add-expense {
    display: flex;
    flex-direction: row-reverse;

    .duplicate-actions {
        > .modal-content {
            width: 50vw !important;
        }

        .modal-footer {
            display: none;
        }

        .with-bg {
            margin-right: 20px;
        }

        .modal-body {
            flex-direction: column;
        }

        .buttons {
            display: flex;
        }

        .tip {
            padding: 20px;
        }
    }

    .form-summary {
        margin-top: 10px;
        
        .table-page .row > :first-child {
            padding-left: 0 !important;
        }

        .title-row {
            font-weight: 600;
            font-size: 11px;
        }

        .title-column {
            font-size: 10px;
        }

        .data-row, .wide-row {
            margin: 0;
            margin-bottom: 1px;

            > div {
                font-size: 11px;
            }
        }

        .wide-row {
            grid-gap: 1.2rem;
            padding-right: 3px;
        }
    }

    &[data-is-resizing="true"] {
        .preview {
            pointer-events: none;
        }
    }

    .subject-tabs {
        display: flex;

        > div {
            @extend .clickable-solid;
        }

        .selected-tab {
            font-weight: bold;
        }

        &[data-subject="seller"] {
            > :first-child {
                @extend .selected-tab;
            }
        }

        &[data-subject="receiver"] {
            > :last-child {
                @extend .selected-tab;
            }
        }

        > :first-child {
            width: 100px;
        }

        > :last-child {
            flex: 1;
        }
    }

    .small-tick {
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -5px;
        right: 1.43rem;
        height: 0.37rem;
        width: 0.37rem;
        border: 2px solid #47CE78;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        opacity: 1;
        transition: opacity .3s;
        pointer-events: none;
    }

    [data-component="input"][data-input-type="select"][data-is-capsuled="true"] .input-outer:after {
        @extend .small-tick;
    }

    .select-box:after {
        @extend .small-tick;
    }

    [data-component="input"][data-input-type="select"][data-is-capsuled="false"]:after {
        @extend .small-tick;
    }

    .miltiple-select:after {
        @extend .small-tick;
        top: calc(50% - 2px) !important;
        width: 0.4px;
        height: 0.4px;
    }

    [data-component="input"][data-input-type="date"] .input-button {
        background-size: 1.19rem 1.54rem;
        right: -4px;
    }

    select {
        font-size: 11px !important;
    }

    .flatpickr-input {
        font-size: 11px !important;
    }

    input {
        font-size: 11px !important;
    }

    .miltiple-select > .face {
        font-size: 11px !important;
    }

    [data-component="button"] {
        font-size: 10px;
        padding: 0;
        min-height: unset;
        height: 25px;
    }

    [data-component="input"] .input {
        height: 20px;
    }

    [data-component="input"] {
        > .title {
            font-size: 9px;
            margin-bottom: -4px;
        }
    }

    .form-summary-inner {
        > .wide-row {
            line-height: unset;
        }

        > .data-row {
            padding: 0;
        }

        > .row:last-child {
            height: unset;

            > div {
                line-height: unset;
            }
        }
    }

    .big {
        font-size: 11px !important;
    }

    .separator-row {
        display: none;
    }

    .no-image {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .receiver-checkbox {
        margin-top: 15px;
    }

    .jpk-entry {
        > .title {
            font-weight: 600;
            font-size: 9px;
        }

        > .select-box {
            background-color: white;
            border-radius: 7px;
            height: 20px;
            padding-left: 10px;
            padding-right: 16px;

            > select {
                font-size: 1.04rem;
            }
        }

        > .miltiple-select {
            background-color: white;
            border-radius: 7px;
            height: 20px;
            min-height: unset;
            border: none;

            > .face {
                min-height: unset;
                height: 100%;
                font-size: 1.04rem;
                font-weight: 400;
                padding-left: 10px;
            }

            &:after {
                right: 16px;
                top: calc(50% - 6px);
            }
        }
    }

    .modal-list {
        margin-top: 30px;

        .category-select {
            display: flex;
            height: 100%;

            > .select-box {
                background-color: white;
                padding: 0 .8rem;
                border-radius: 5px;
            }

            > :nth-child(2) {
                margin-left: 30px;
            }
        }
    }

    > .resize-bar {
        position: relative;
        background-color: rgb(232, 240, 234);
        width: 10px;
        cursor: col-resize;
        box-shadow: 0 0 15px rgba(black, .2);
        clip-path: inset(0px -15px 0px 0px);

        &:after {
            position: absolute;
            content: url("/assets/icons/resize_handle.svg");
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(.7);
        }
    }

    .loading-file {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .subject {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-areas: "f1 f1 f1 f2 f2 f3 f3 f3" "f6 f6 f7 f8 f9 f10 f10 f11";
        grid-gap: 1rem;
        row-gap: 3px;
        font-size: 1rem;

        .gus-button {
            top: unset;
            bottom: 0;
            height: 20px;
            padding: 0;
            right: -30px;
        }
    }

    > .add-expense-form {
        flex: 1;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 40px;
        padding-top: 0;

        > .expense-products {
            margin-top: 10px;
        }

        > .form-summary {
            margin-bottom: 20px;
            margin-top: 0 !important;

            .title-row {
                > :first-child {
                    padding-left: 0;
                }
            }
        }

        > .with-bg {
            margin-top: 15px;
            margin-right: 5px;
        }

        > div {
            row-gap: 5px;
        }

        .form-title {
            position: relative;
            font-size: 12px;
            font-weight: 500;
            color: #191717;
            margin-top: 6px;
            margin-bottom: 3px;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 1px;
                background-color: rgba(#18152B, .31);
            }
        }

        > .invoice-form {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-column-gap: 1rem;
        }

        > .buyer-form {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-areas: "f1 f1 f2 f2" "f3 f3 f4 f5" "f6 f7 f8 ." "f9 f10 . ." "f11 f11 f12 f12";
            grid-gap: 1rem;
            font-size: 1rem;
        }

        > .seller-form {
            font-size: 1rem;
        }

        > .form-list {
            padding: 1rem 2.86rem;
    
            > .header {
                font-weight: 600;
                letter-spacing: .5px;
                font-size: 1rem;
                margin-bottom: 5px;
            }
    
            [data-input-type="checkbox"] {
                height: 100%;
    
                > .checkbox-input {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
    
                    > .check {
                        position: relative;
                    }
                }
            }
    
            > .item, > .header {
                display: grid;
                grid-template-columns: auto minmax(85px, 6%) minmax(75px, 6%) 9% 9% 10% 9% 5% 2%;
                grid-template-rows: 1fr;
                gap: 1rem 1.1rem;
                align-items: center;
            }
    
            > .footer {
                width: 100%;
            }
    
            [data-component="button"] {
                padding: .8rem 0;
            }
    
            &-readonly {
                .item span {
                    display: inline-block;
                    box-sizing: border-box;
                    width: 100%;
                    height: 3.2rem;
                    line-height: 3.2rem;
                    border-radius: 7px;
                    padding: 0 .8rem;
                    font-size: 1.04rem;
                    border: 1px solid transparent;
                    transition: border-color .3s;
                    outline: none;
                    background-color: white;
                    font-family: "Poppins", sans-serif;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    > .preview {
        background-color: white;
        width: 50%;
        position: relative;

        > .upload {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .upload-bar {
                width: 90%;
            }
        }

        > .new-window-button {
            position: absolute;
            right: 15px;
            top: 15px;
            width: 40px;
            height: 40px;
            background-image: url("/assets/icons/new_window_icon.svg");
            background-repeat: no-repeat;
            background-size: 30px 30px;
            background-position: center;
            cursor: pointer;
            transition: opacity .15s;

            &:hover {
                opacity: .61;
            }
        }

        &[data-is-preview="true"] {
            > div > div > div {
                height: 100%;
                width: 100%;
            }
        }

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }

        embed {
            width: 100%;
            height: 100%;
        }
    }
}
