.subscriptions {
    > .title {
        font-size: 32px;
        font-weight: 600;
        text-transform: uppercase;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 72px;
        margin-bottom: 46px;
    }

    > .services {
        display: flex;
        justify-content: center;

        > .service {
            display: flex;
            background-color: white;
            border-radius: 62px;
            padding: 47px 77px;
            width: 90%;
            box-shadow: 9px 17px 24px rgba(black, .07);

            > .main {
                flex: 1;

                > .name {
                    font-size: 26px;
                    font-weight: 600;
                    margin-bottom: 50px;
                }

                > .description {
                    font-size: 14px;
                }
            }

            > .trailing {
                display: flex;
                flex-direction: column;
                align-items: center;

                > .price {
                    font-size: 38px;
                    font-weight: 600;
                }

                > .netto {
                    font-size: 14px;
                    margin: 15px 0;
                }
            }
        }
    }

    > .cards {
        display: flex;
        justify-content: space-around;
        
        > .card {
            position: relative;
            width: 332px;
            height: 700px;
            background-color: white;
            border-radius: 62px;

            > .bubbles {
                z-index: 50;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 340px;
                background-size: cover;
                background-repeat: no-repeat;
            }

            &[data-color="green"] {
                > .bubbles {
                    background: url("/assets/icons/card_green.png");
                }

                .buy-button {
                    background-color: #0BBBBF;
                }

                .details-button {
                    color: #0BBBBF;
                }
            }

            &[data-color="blue"] {
                > .bubbles {
                    background: url("/assets/icons/card_blue.png");
                }

                .buy-button {
                    background-color: #1B8DFE;
                }

                .details-button {
                    color: #1B8DFE;
                }
            }

            &[data-color="purple"] {
                > .bubbles {
                    background: url("/assets/icons/card_purple.png");
                }

                .buy-button {
                    background-color: #9634FA;
                }

                .details-button {
                    color: #9634FA;
                }
            }

            > .texts {
                position: absolute;
                left: 0;
                right: 0;
                top: 92px;
                z-index: 100;
                display: flex;
                flex-direction: column;
                align-items: center;

                > .name {
                    font-size: 32px;
                    font-weight: 600;
                    color: white;
                    text-transform: uppercase;
                }

                > .length {
                    font-size: 14px;
                    color: white;
                    margin-top: -6px;
                }

                > .price {
                    font-size: 26px;
                    color: white;
                    margin-top: 23px;
                }

                > .period {
                    font-size: 14px;
                    color: white;
                    margin-top: -6px;
                }

                > .features {
                    margin-top: 105px;

                    > .feature {
                        width: 278px;
                        height: 57px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #F6F4F9;
                        font-size: 14px;

                        &[data-is-even="true"] {
                            background-color: white;
                        }
                    }
                }

                > .buy-button {
                    width: 240px;
                    height: 58px;
                    border-radius: 29px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 64px;
                    color: white;
                    font-size: 14px;
                    font-weight: 600;
                    cursor: pointer;
                    margin-bottom: 12px;

                    &:hover {
                        opacity: .61;
                    }
                }

                > .details-button {
                    font-size: 14px;
                    cursor: pointer;

                    &:hover {
                        opacity: .61;
                    }
                }
            }
        }
    }
}