@import "style-base";

.selection-actions-bar {
    display: flex;
    align-items: center;

    > .counter {
        color: #39BD92;
        font-size: 13px;
    }

    > .actions {
        display: flex;

        > .action {
            @extend .clickable-solid;
            padding: 0 18px;
            display: flex;
            align-items: center;
            font-size: 11px;
            font-weight: 500;
        }
    }

    > .close {
        @extend .clickable-solid;
        font-size: 15px;
        margin-left: 27px;
        display: flex;
        align-items: center;
        color: #39BD92;
    }
}
