.context-switch {
    max-width: 245px;

    > .select-box {
        > select {
            padding-right: 30px;
            font-size: 14px;
            text-align: right;
            text-overflow: ellipsis;
        }
    }
}
