.auth-frame {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;

    > .background {
        width: 33%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("/assets/icons/auth_background.png");
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;

        .logo {
            display: flex;

            img {
                width: 9rem;
            }
        }
    }

    > .form {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        overflow: auto;

        > .content {
            width: 75%;
            color: #191717;
            margin: auto;
            padding: 6rem 0 4rem;

            .input {
                height: 4.07rem;
                border-radius: 2rem;
                font-size: 1rem;
                font-weight: 300;
                padding: 0 2.5rem;
                width: 100%;
                margin-bottom: .65rem;
                --border-default-color: #CBCBCB;
            }

            .positive-button {
                position: relative;
                background-size: 200% 200%;
                background-image: linear-gradient(142deg, #4DD57E 0%, #38B794 44%, #4DD57E 100%);
                transition: .4s;
                height: 4.07rem;
                border-radius: 2rem;
                font-size: 1rem;
                font-weight: 600;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 1.49rem;
                cursor: pointer;

                > .spinner {
                    position: absolute;
                    left: 11px;
                }

                &[data-is-activity-showing="true"] {
                    pointer-events: none;
                }

                &[data-is-disabled="true"] {
                    pointer-events: none;
                    opacity: .61;
                }

                &:hover {
                    background-position: right center;
                }
            }

            > .title {
                font-size: 1.86rem;
                font-weight: 600;
                margin-bottom: 2.64rem;
                text-align: center;
                text-transform: uppercase;
            }

            > .subtitle {
                font-size: 1rem;
                font-weight: 300;
                width: 55%;
                min-width: 300px;
                margin: 0 auto 6rem;
                text-align: center;
            }

            .tabs {
                height: 2.86rem;
                position: relative;
                display: flex;
                margin-bottom: 2.14rem;

                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 1px;
                    background-color: rgba(#191717, .08);
                }

                .tab-button {
                    position: relative;
                    font-size: 1.14rem;
                    font-weight: 300;
                    margin-right: 1.43rem;

                    &[data-is-selected="true"] {
                        font-weight: 600;

                        &:after {
                            content: "";
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            height: 2px;
                            background-color: #191717;
                        }
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 2px;
                        background-color: #191717;
                    }
                }
            }

            .bottom {
                display: grid;
                grid-template-rows: repeat(3, 1fr);
                grid-gap: .65rem;
                margin: 0 auto;
                width: 70%;

                .tip {
                    font-weight: 300;
                    margin-right: 2.64rem;
                }

                .social {
                    font-weight: 600;
                    margin-left: 1.71rem;
                }

                [data-component="button"] {
                    padding: 1.1rem 1.2rem;
                    min-width: auto;

                    &:hover {
                        filter: brightness(1.08);
                    }

                    &:nth-of-type(1) {
                        background: #3B5999;
                    }

                    &:nth-of-type(2) {
                        background: #DD4C39;
                    }

                    &:nth-of-type(3) {
                        background: #007BB6;
                    }
                }
            }

            .login-and-registration-panel {
                display: grid;
                flex-direction: row;
                grid-template-columns: 1fr 1fr;
                grid-gap: 13%;

                .panel--left {
                    .tabs .tab-button {
                        font-weight: 600;

                        &:last-child {
                            display: none;
                        }
                    }
                }

                .panel--right {
                    .tabs .tab-button:first-child {
                        display: none;
                    }
                }

                [data-component="button"] {
                    border-radius: 2rem;
                    color: white;
                }

                .or {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 500;
                    height: 1px;
                    background: linear-gradient(to right, #81807817, #81807896, #81807817);
                    text-transform: uppercase;
                    color: #002F34;
                    margin: 2.14rem;

                    span {
                        background: white;
                        padding-left: 2.14rem;
                        padding-right: 2.14rem;
                    }
                }
            }
        }
    }
}
