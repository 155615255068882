.category-section {
    padding: 2.68rem 2.14rem;
    display: grid;
    grid-gap: 1.57rem 1.07rem;
    grid-template-columns: repeat(6, 1fr);
}

.category-details {
    width: 100%;
    
    .sell-form {
        @extend .category-section;
        grid-template-rows: repeat(3, 1fr);
        grid-template-areas: "f1 f1 f1 f2 f2 f2" "f3 f3 f4 f4 f5 f5" "f6 f7 f8 . . .";
        padding-bottom: 0;
    }

    .buy-form {
        @extend .category-section;
        grid-template-rows: repeat(5, 1fr);
        grid-template-areas: "f1 f1 f1 f2 f2 f2" "f3 f3 f4 f4 f5 f5" "f6 f6 . . f7 f8" ". . . . f9 f10" ". . . . f11 .";
        padding-bottom: 0;
        margin-bottom: -20px;

        [data-input-type="number"] {
            transform: translateY(8px);
        }
    }

    .jpk-form {
        @extend .category-section;
        grid-template-rows: repeat(3, 1fr);
        grid-template-areas: "f1 f1 f1 f1 f1 f1" "f2 f2 f2 f2 f2 f2" "f3 f3 f3 f3 f3 f3";
        padding-bottom: 2rem;

        .miltiple-select {
            background-color: white;
            border: none;
        }

        .title {
            font-weight: 600;
            font-size: 1rem;
            margin-bottom: 0;
            letter-spacing: .5px;
        }
    }

    .picker {
        --zeroTransform: translateY(-100%);
    }
}