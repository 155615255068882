.registry-section {
    padding: 2.68rem 2.14rem;
    display: grid;
    grid-gap: 1.57rem 1.07rem;
    grid-template-columns: repeat(6, 1fr);
}

.vat-registry {
    .modal-list {
        flex: 2 !important;
        padding-left: 2.14rem;
    }

    .split {
        display: flex;

        > div {
            flex: 1;

            &:first-child {
                margin-right: 20px;
            }

            &:last-child {
                margin-left: 20px;
            }
        }
    }

    > .tabbed-nav {
        &[data-is-readonly] {
            display: none;
        }
    }

    > .sell-form {
        .dates {
            @extend .registry-section;
            grid-template-rows: repeat(2, 1fr);
            grid-template-areas: "f4 f4 f4 f5 f5 f5" "f6 f6 f6 f7 f8 f8";
            
            [data-input-type="checkbox"] {
                transform: translateY(13px)
            }
        }
    }

    .general {
        @extend .registry-section;
        flex: 2;
        grid-template-rows: 1fr;
        grid-template-areas: "f1 f1 f2 f2 f3 f3";
    }

    .payments {
        @extend .registry-section;
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas: "f8 f8 f9 f9 f10 f10" "f11 f11 f12 f12 f12 f13";

        .currency {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            
            > .label {
                font-size: 1rem;
                font-weight: 600;
            }
    
            > .select-box {
                background-color: white;
                border-radius: 7px;
                height: 2.7rem;
                padding-left: 10px;
                padding-right: 16px;
    
                > select {
                    font-size: 1.04rem;
                }
            }
        }
    }

    .other {
        @extend .registry-section;
        grid-template-rows: 1fr;
        grid-template-areas: "f13 f13 f13 f14 f14 f14";
    }

    > .buy-form {
        .dates {
            @extend .registry-section;
            grid-template-rows: repeat(3, 1fr);
            grid-template-areas: "f4 f4 f4 f5 f5 f5" "f6 f6 f6 f7 f7 f7" "f8 f8 f8 f13 f14 f14";
            
            [data-input-type="checkbox"] {
                transform: translateY(13px)
            }
        }
    }

    .form-summary {
        padding: 2.68rem 2.14rem;
    }

    .subject {
        flex: 1;
        padding: 2.68rem 2.14rem;
        width: 100%;
        display: flex;

        > label {
            display: none;
        }

        > :first-child {
            display: flex !important;
            margin-right: 15px;
            flex: 1;
        }

        .gus-button {
            display: none;
        }
    }
}
