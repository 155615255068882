@import "style-base";

.cost-tags {
    display: grid;
    grid-template-columns: 200px 200px;
    grid-column-gap: 30px;
    padding: 30px;

    .action-button {
        @extend .clickable-solid;
        width: 30px;
        height: 30px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 50%;

        &[data-kind="edit"] {
            background-image: url("/assets/icons/pen.svg");
        }

        &[data-kind="delete"] {
            background-image: url("/assets/icons/close.svg");
        }

        &[data-kind="accept"] {
            background-image: url("/assets/icons/green_tick.svg");
        }
    }

    .tag {
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;

        &[data-kind="email"] {
            > .icon {
                background-image: url("/assets/icons/email.svg");
            }
        }

        &[data-kind="sms"] {
            > .icon {
                background-color: blue;
            }
        }

        > .icon {
            width: 30px;
            height: 30px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 70%;
            margin-right: 15px;
        }

        .input-element {
            margin-right: 5px;
        }
    }

    > [role="button"] {
        margin-left: 5px;
    }

    .tag-creator {
        display: flex;
        align-items: flex-end;
    }

    > .schedules {
        > .schedule {
            display: flex;
            align-items: flex-start;

            > [data-kind="multipleSelect"] {
                width: 300px;
                margin-right: 10px;

                .picker {
                    top: 0;
                    --zeroTransform: translateY(-100%);
                }

                .bubble {
                    height: unset;
                    min-height: 30px;

                    > .miltiple-select {
                        min-height: 30px;

                        > .face {
                            min-height: 30px;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            padding-right: 20px;
                        }
                        
                        &:after {
                            right: 7px;
                        }
                    }
                }
            }

            > .period-form {
                display: flex;
                align-items: flex-end;

                .react-time-input-picker-wrapper {
                    height: 50px;
                }

                > [data-kind="select"] {
                    width: 140px;
                    margin-right: 10px;
                }

                > [data-kind="integer"] {
                    width: 100px;
                    margin-right: 10px;
                }

                > .form-row {
                    width: 400px;
                    margin-bottom: 0;
                }

                .inputWrapper {
                    input {
                        border: 1px solid lightgray;
                    }
                }

                > .action-button {
                    margin-top: 20px;
                }
            }

            > .action-button {
                color: #47CE78;
                font-size: 13px;
                margin-left: 16px;
                margin-top: 24px;
            }

            > .spinner {
                margin-top: 22px;
                margin-left: 10px;
            }
        }
    }
}