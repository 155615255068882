@import "vars", "mixins";

.gus-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 49%;
    max-width: 13.5rem;
    height: 3.8rem;
    z-index: 3;
    padding: 1rem .8rem;
    color: white;
    text-align: center;
    font-weight: 400;
    background: linear-gradient(142deg, #47CE78 0%, #39BD99 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    line-height: 1.1rem;
    text-transform: uppercase;

    @include animation-keyframes {
        from {
            opacity: 0;
            transform: translateX(20px);
        }

        to {
            opacity: 1;
            transform: none;
        }
    }
    animation-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
    animation-duration: .3s;

    svg {
        width: 1rem;
        height: auto;
    }

    &[data-component="button"] > .icon + .title {
        font-weight: normal;
        margin-left: .7rem;
        margin-bottom: 0;
    }
}
