.bank-registry-details {
    .bank-registry-section {
        padding: 2.68rem 2.14rem;
        display: grid;
        grid-gap: 1.57rem 1.07rem;
        grid-template-columns: repeat(12, 1fr);
    }

    > .genral-section {
        @extend .bank-registry-section;
        grid-template-areas: "f1 f1 f1 f2 f2 f2 f3 f3 f3 f4 f4 f4" "f5 f5 f5 f6 f6 f6 f7 f7 f7 f8 f8 f8" "f9 f9 f9 f9 f9 f9 f9 f9 f9 f9 f9 f9";
    }

    > .subject {
        @extend .bank-registry-section;
        grid-template-columns: repeat(8, 1fr);
        grid-template-areas: "f1 f1 f1 f2 f2 f3 f3 f3" "f6 f6 f7 f8 f9 f10 f10 f11";

        .gus-button {
            top: 20px;
            height: 29px;
        }
    }

    > .general-bottom-section {
        @extend .bank-registry-section;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: "f1 f2";
    }

    .modal-list {
        padding: 2.68rem 2.14rem;
    }
}
