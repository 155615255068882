.grid {
    position: relative;
    height: 99999px;
    
    .chart {
        width: 450px;
    }

    .breakdown {
        width: 300px;
    }

    > .tile {
        width: fit-content;
        position: absolute;
    }
}

.dashboard {
    position: relative;
    padding: 1.07rem 2.14rem 1.07rem 2.86rem;

    > .loading {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url("/assets/icons/dashboard_loading.svg");
        background-position: center center;
        background-repeat: no-repeat;
        opacity: 1;
        transition: opacity .3s;
        pointer-events: none;

        &[data-is-done="true"] {
            opacity: 0;
        }

        $rotate: -16deg;

        @keyframes shine {
            from {
                transform: rotate($rotate);
            }
            to {
                transform: translateY(150vh) rotate($rotate);
            }
        }

        > .sheen {
            position: absolute;
            left: -100px;
            top: -50%;
            width: 130%;
            height: 200px;
            background: rgb(255,255,255);
            background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
            transform: rotate($rotate);
            animation: shine 2s infinite;
        }
    }

    > .tile {
        margin: 0 5px;
    }
}

.grid, .dashboard {
    .tile {
        padding: 25px;
        background-color: white;
        box-shadow: 0 0 16px 1px rgba(black, .16);
        border-radius: 6px;
        border: 1px solid #CBCCC7;

        > .title {
            color: #3C4126;
            font-size: 14px;
            margin-bottom: 35px;
        }
    }
}