.vat-entries {
    padding: 2.68rem 2.14rem;

    > .header {
        font-weight: 600;
        letter-spacing: .5px;
        font-size: 1rem;
        margin-bottom: 5px;
    }

    [data-input-type="checkbox"] {
        height: 100%;

        > .checkbox-input {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;

            > .check {
                position: relative;
            }
        }
    }

    > .item, > .header {
        display: grid;
        grid-template-columns: auto 13% 9% 13% 10% 12% 8% 3%;
        grid-template-rows: 1fr;
        gap: 1rem 1.1rem;
        align-items: center;
    }

    > .footer {
        width: 100%;
    }

    [data-component="button"] {
        padding: .8rem 0;
    }

    &-readonly {
        .item span {
            display: inline-block;
            box-sizing: border-box;
            width: 100%;
            height: 3.2rem;
            line-height: 3.2rem;
            border-radius: 7px;
            padding: 0 .8rem;
            font-size: 1.04rem;
            border: 1px solid transparent;
            transition: border-color .3s;
            outline: none;
            background-color: white;
            font-family: "Poppins", sans-serif;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}