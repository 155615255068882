.multiple-assign {
    width: 70vw;

    > .section {
        background-color: white;
        margin: 20px;
        padding: 20px;
        border-radius: 10px;

        > [data-input-type="checkbox"] {
            margin-bottom: 32px;
            margin-top: -16px;
        }

        > .title {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 24px;
        }

        > .roles {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 25px;
        }

        > .companies {
            display: grid;
            grid-template-columns: 48% 48%;
            grid-gap: 10px;
        }
    }
}