.label-page {
    padding: 1.2rem 2.14rem 1.07rem 2.86rem;
    height: 100%;

    .label-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    .positive-button {
        margin-left: 10px;
        margin-right: 10px;
        padding: 4px 8px;
        background-color: #47CE78;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &[data-is-loading="true"] {
            cursor: unset;
            opacity: 0.61;
        }
    }

    .delete-button {
        background-image: url("/assets/icons/red_cross.svg");
        background-position: left center;
        background-repeat: no-repeat;
        height: 15px;
        width: 15px;
        cursor: pointer;
    }

    .label-creator {
        display: flex;

        .label-text {
            min-width: 125px;
        }
    }
}
