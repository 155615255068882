@import "style-base";

.roles {
    display: flex;
    flex-direction: column;
    
    > .descendant {
        flex: 1;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}
