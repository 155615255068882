.cost-invice-summary {
    > .labels {
        font-size: 16px;
        color: #5e5e5e;
        font-weight: 500;

        > .form-row {
            > div {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .form-row {
        grid-gap: 0;
        margin: 0;
        height: 33px;
        border-bottom: 1px solid $separator-color;
    }

    > .form-row {
        > div {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        > .form-entry {
            > .input-element {
                > .name {
                    display: none;
                }
                > .bubble {
                    background-color: unset;
                    border: none;
                }
            }
        }
    }
}
