@mixin animation-keyframes {
    $animation-name: unique-id();
    animation-name: $animation-name;

    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin responsive-units {
    font-size: 11px;

    @media screen and (min-width: 1400px) {
        font-size: 12px;
    }

    @media screen and (min-width: 1600px) {
        font-size: 13px;
    }

    @media screen and (min-width: 1920px) {
        font-size: 14px;
    }
}
