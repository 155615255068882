.breakdown {
    > .divisions {
        > .breakdown-row {
            height: 50px;
            position: relative;
            display: flex;
            justify-content: space-between;

            :nth-child(1) {
                font-size: 10px;
                color: #3C4126;
            }

            :nth-child(2) {
                font-size: 12px;
                color: #55B478;
            }

            &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 27px;
                right: 0;
                height: 1px;
                background-color: rgba(#CBCCC7, .25);
            }
        }
    }
}
