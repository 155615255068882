.section {
    display: flex;
    border-bottom: 1px solid #eaecf0;

    &[data-background="blue"] {
        background-color: #F4FDFF;
    }

    &[data-background="light-blue"] {
        background-color: #F9FEFF;
    }

    > .title {
        font-size: 16px;
        letter-spacing: 5px;
        text-transform: uppercase;
        color: #929292;
        font-weight: 400;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }

    > .content {
        flex: 1;
    }

    &[data-is-expanded="false"] {
        padding-bottom: 10px;

        > .content {
            max-height: 0;
            overflow: hidden;
        }

        > .title {
            writing-mode: unset;
            transform: unset;
            margin-left: 0;
            position: relative;
            left: 0;
        }
    }
}
