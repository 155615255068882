.labels-input {
    $creator-color: rgb(186, 186, 186);
    
    //
    width: 250px;
    transform: translateY(300px);
    //

    > .labels {
        display: flex;
        flex-wrap: wrap;

        .label {
            margin-right: 5px;
            margin-bottom: 5px;
            padding: 3px 5px;
            box-sizing: border-box;
            border-radius: 10px;
        }

        .creator {
            position: relative;
            color: white;
            background-color: $creator-color;
            min-width: 50px;
        }

        .creator-with-button {
            display: flex;

            > .plus-button {
                @extend .clickable-solid;
                background-color: $creator-color;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 22px;
                height: 22px;
                border-radius: 11px;
                cursor: pointer;
            }

            &[data-is-placeholder="true"] {
                > .plus-button {
                    display: none;
                }
            }
        }
    }

    .label-suggestions {
        @extend .context-appear;
        box-shadow: 0 0 16px 1px rgba(black, .3);
        position: absolute;
        top: 100%;

        > .label-suggestion {
            @extend .clickable-solid;
            padding: 3px 5px;
        }
    }
}

.color-picekr-appear {
    @extend .context-appear;

    > .color-picker {
        position: absolute;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        box-shadow: 0 0 16px 1px rgba(black, .3);
        background-color: white;
        transform: translateY(-100%);

        > .hue-tile {
            @extend .clickable-solid;
            width: 20px;
            height: 20px;
            margin: 1px;
        }
    }
}
