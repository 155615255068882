@import "_vars", "style-base";

.preview-body {
    > .filters {
        height: 100%;

        > .cells {
            flex: 1;
            overflow: scroll;
            @extend .hideen-scrollbars;
        }
    }
}

.text-filter {
    > input {
        height: 3.5rem;
        border-radius: 7px;
        border: 1px solid #E7E5E9;
        outline: none;
        font-size: 1rem;
        padding: 0 22px;
        width: 100%;
    }
}