@import "_vars";

.filters {
    z-index: 100;
    padding: 5rem 2.3rem 2.3rem;
    background: white;
    min-height: 100vh;
    width: 43rem;
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;

    [data-input-type="select"] {
        border-radius: 7px;
        border: 1px solid #E7E5E9;
        min-height: 3.5rem;

        > .input {
            margin-top: 4px;
        }
    }

    .spinner {
        position: absolute;
        left: 50%;
        top: 30%;
    }

    > .cells {
        flex: 1;
    }

    > .buttons {
        position: relative;
        display: flex;
        margin-top: 20px;
        justify-content: flex-end;
        color: $green;

        &:after {
            position: absolute;
            top: -40px;
            left: 0;
            right: 0;
            height: 20px;
            content: "";
            background: rgb(0,255,255);
            background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        }

        > div {
            margin-left: 20px;
        }
    }

    > .payment {
        display: flex;

        > :first-child {
            flex: 1;
            margin-right: 25px;
        }

        > :last-child {
            flex: 2;
        }
    }

    > .cross {
        position: absolute;
        top: 1.5rem;
        left: 2.3rem;
        font-size: 2rem;
        cursor: pointer;
        transition: opacity .3s;
        opacity: 1;
        font-weight: 300;

        &:hover {
            opacity: .5;
        }
    }

    .filter-cell {
        margin-bottom: 1.5rem;
        display: flex;
        align-items: center;

        > .title {
            font-size: 1.07rem;
            font-weight: 600;
            flex: 1;
        }

        [data-input-type="checkbox"] {
            margin: 0;
            padding: 0;

            > .title {
                font-size: 1rem;
                font-weight: 300;
            }

            .check {
                top: 2px !important;
            }
        }

        .content {
            cursor: pointer;
            flex: 2;
        }
    }

    .checkboxes {
        display: flex;

        [data-input-type="checkbox"] {
            margin-right: 45px;
        }
    }   
}
