.products {
    display: flex;
    padding: 1.07rem 2.14rem 1.07rem 2.86rem;
    flex-direction: column;
    padding-bottom: 115px;

    &[data-is-preview="true"] {
        .modal-footer {
            visibility: hidden;
        }

        [data-input-type="date"] {
            pointer-events: none;
        }

        [data-input-type="checkbox"] {
            pointer-events: none;
        }
    }

    > .header {
        display: flex;
        flex-direction: row;
        padding: .85rem 0 1.6rem;
        align-items: center;

        > .search {
            width: 18.9rem;
            display: block;
            margin-right: 1.7rem;

            input {
                width: 100%;
                border-radius: 7px;
                padding: .7rem 1.07rem;
                height: 2.79rem;

                &::placeholder {
                    color: #191717;
                }
            }
        }

        [data-component="button"] {
            margin-right: 2.5rem;

            .icon svg {
                width: 1.4rem;
                height: auto;
            }

            [data-icon-name="filter"] {
                svg {
                    width: 1rem;
                }
            }

            .title {
                letter-spacing: .5px;
                margin-left: .75rem;
            }
        }
    }

    > [data-component="table"] {
        flex: 1;
        font-size: 1rem;

        > .table-header {
            display: grid;
            grid-gap: .7rem;
            grid-template-columns: 11% 25% 15% 15% 15% 1fr 2.5%;
            grid-template-rows: 1fr;
            grid-template-areas: "f1 f2 f3 f4 f5 f6 f7";
        }

        > .table-body > .row {
            margin-bottom: .5rem;
            display: grid;
            grid-gap: .7rem;
            grid-template-columns: 11% 25% 15% 15% 15% 1fr 2.5%;
            grid-template-rows: 1fr;
            grid-template-areas: "f1 f2 f3 f4 f5 f6 f7";
            min-height: 3.4rem;
            align-items: center;

            > div:first-child {
                padding-left: 2.4rem;
            }

            &:hover {
                background-color: #F3F3F3;
            }
        }
    }

    > .footer {
        position: absolute;
        right: 3.3rem;
        bottom: 0;
    }

    [data-component="modal"] {
        .modal-content {
            width: 61.2rem;
        }
    }
}

.products--add {
    width: 100%;
    padding: 2.68rem 2.14rem;
    display: grid;
    grid-gap: 1.57rem 1.07rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr 2.2rem 1fr 1fr 3px .7rem 1.43rem;
    grid-template-areas: "f1 f1 f2" "f3 f4 f5" ". . ." "f6 f7 f7" "f8 f9 f10" ". . ." "status status status" "f11 f12 .";

    > .status {
        grid-area: status;
        font-weight: bold;
    }
}
