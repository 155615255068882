@import "_vars", "style-base";

$menu-animation-time: .2s;

#nav {
    @extend .hideen-scrollbars;
    position: fixed;
    height: 100vh;
    width: 170px;
    background: white;
    color: black;
    user-select: none;
    overflow: auto;
    box-shadow: 0 0 16px 1px rgba(0, 0, 0, .16);
    z-index: 1;
    transition: width $menu-animation-time;
    
    &[data-is-collapsed="true"] {
        width: 50px;

        span {
            opacity: 0;
        }

        > .logo {
            margin-left: 5px;
            margin-right: 5px;
        }

        > .header__infos {
            &__company {
                opacity: 0;
            }
        }
    }

    @media print {
        display: none;
    }

    > .header__infos {
        height: 68px;
        display: flex;
        flex-direction: row;
        font-size: 1rem;
        font-weight: normal;
        text-transform: initial;
        letter-spacing: .1rem;

        &__company {
            height: 68px;
            margin-top: -29px;
            margin-bottom: 10px;
            margin-left: 10px;
            margin-right: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: opacity $menu-animation-time;

            div {
                width: 100%;
                overflow: hidden;
                text-align: center;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            div:first-child {
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box !important;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: normal;
            }
        }
    }

    > .logo {
        text-align: center;
        margin: 2.9rem 50px 10px;
        font-weight: bold;
        font-size: 22px;
        transition: margin $menu-animation-time;
        height: 96px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .item {
        font-weight: 300;
        min-height: 3rem;
        min-width: 155px;
        font-size: 1.04rem;
        margin: .43rem 1.43rem .43rem 0;
        background-color: transparent;
        transition: background-color .3s;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 0 0 1.2rem;
        border-radius: 0 25px 25px 0;
        position: relative;

        > img {
            width: 22px;
            height: 18px;
        }

        span {
            position: absolute;
            left: 3.7rem;
            transition: opacity $menu-animation-time;
        }

        &:hover {
            cursor: pointer;
            background: linear-gradient(142deg, rgba(71, 206, 120, .15) 0%, rgba(57, 189, 153, .15) 100%);
        }

        &:focus, &[data-is-active="true"], &:focus:hover, &[data-is-active="true"]:hover {
            cursor: pointer;
            background: linear-gradient(142deg, rgba(71, 206, 120, 1) 0%, rgba(57, 189, 153, 1) 100%);
            color: white;

            img {
                filter: brightness(0) invert(1);
            }
        }
    }

    > .main {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-width: 15.5rem;
    }

    > .bottom {
        padding: 3.57rem 0;
    }
}
