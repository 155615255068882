.checkbox {
    @include responsive-units;
    display: flex;
    position: relative;
    padding-left: 25px;
    height: 20px;
    cursor: pointer;
    user-select: none;

    &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &__mark {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        background-color: #FFF;
        border: 1px solid #EAECF0;

        &:after {
            content: "";
            position: absolute;
            display: none;
            top: 50%;
            left: 50%;
            width: 31%;
            height: 49%;
            border: solid $green;
            border-width: 0 2px 2px 0;
            transform: translate(-50%, -62%) rotate(45deg);
        }
    }

    &:hover .checkbox__input ~ .checkbox__mark {
        background-color: #EAECF0;
    }

    .checkbox__input:checked ~ .checkbox__mark {
        background-color: #FFF;
    }

    .checkbox__input:checked ~ .checkbox__mark:after {
        display: block;
    }
}
