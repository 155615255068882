@import "style-base";

.tag-picker {
    position: relative;
    height: 100%;

    > input {
        width: 100%;
        height: 100%;
        max-width: 100%;
        min-width: 100%;
        padding-left: 0;
        border: none;
        transition: padding-left, 0.3s;
    }

    > .spinner {
        position: absolute;
        left: 1px;
        top: 5px;
        display: none;
    }

    &[data-is-creating="true"] {
        > input {
            padding-left: 30px;
        }

        > .spinner {
            display: block;
        }
    }

    > .options {
        @extend .context-appear;
        position: absolute;
        background-color: white;
        box-shadow: 0px 0px 3px #dbdada;
        width: 100%;
        z-index: 100;

        > .option {
            padding: 10px 20px;

            &:hover {
                color: white;
                background-color: yellowgreen;
            }
        }
    }
}