.text-control {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;

    &.kind-price {
        text-align: end;
    }
}
