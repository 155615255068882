@import "style-base";

.add-income-page {
    overflow: hidden !important;

    .invoice-preview {
        width: 100%;
        padding: 5px 0 50px;
    }

    .rhs {
        background: #FFF;
    }

    [data-component="horizontal-nav"] {
        position: absolute;
        top: 41px;
        transform: translateY(-100%);
        width: 100%;
        padding: 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        max-width: calc(100% - 564px);
        margin: 0 auto;
        height: 33px;
        left: 282px;
        right: 282px;
        border: none;
        z-index: 1;

        > .item {
            padding: 0 0.9vw;
            cursor: pointer;
            transition: all .3s;
            color: #5E5E5E;
            margin: 0 .3vw;
            position: relative;
            height: 100%;
            text-transform: uppercase;
            border: 1px solid #D2FFE1;
            border-bottom: 1px solid rgb(237 237 237);
            border-radius: 6px 6px 0 0;
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1;
            font-size: 8px;
            text-align: center;
            background-color: #D2FFE1;
            border-radius: 6px 6px 0 0;

            @media screen and (min-width: 1200px) {
                font-size: .75vw;
            }

            @media screen and (min-width: 1920px) {
                font-size: 14px;
                padding: 0 19px;
                margin: 0 5px;
            }

            &:hover {
                background-color: #c7fad8;
            }

            &:after,
            &:before {
                display: none;
            }

            &[data-is-active="true"] {
                border-color: $green;
                background-color: #FFF;
                color: $green;

                &:hover {
                    border-color: $green;
                    background-color: #FFF;
                    color: $green;
                }
            }
        }
    }

    .actions {
        flex: 1;
        display: flex;
        align-items: flex-start;
        background-color: #FFF;
        padding: 16px 39px;
        justify-content: flex-end;

        [data-component="button"] {
            font-size: 11px;
            font-weight: $regular;
            min-height: 20px;
            padding: 2px 9px;
            background-color: #FFF;
            text-transform: uppercase;
            border: 1px solid $green;
            border-radius: 6px;
            cursor: pointer;
            margin-left: 12px;
            transition: all .2s ease;

            &:hover {
                background-color: #0EC550;
                color: #FFF;
            }
        }

        > [data-component="button"] {
            width: 100px;
        }

        > .other {
            display: flex;

            > div {
                margin-bottom: 5px;
            }
        }
    }
}

// .add-income-page {
//     overflow: hidden !important;

//     .add-income--form {
//         padding-bottom: 30px;
//     }

//     .rhs {
//         overflow-y: scroll;
//     }

//     .lhs {
//         @extend .hideen-scrollbars;
//         overflow-y: scroll;
//     }

//     .form-other {
//         grid-template-columns: repeat(4, 12rem) 1fr !important;
//     }

//     .invoice-live-preview {
//         width: 100%;

//         .invoice-table-container {
//             width: 100%;
//         }

//         .invoice-preview {
//             width: 100%;
//             padding: 5px 0 50px;
//         }
//     }
// }
