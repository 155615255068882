$separator-color: #dcdcdc;

.cost-invoice-products-section {
    > .list {
        > .labels {
            > .form-row {
                height: 39px;
                grid-gap: 1px;
                margin-bottom: 0;

                > div {
                    font-size: 16px;
                    color: #5e5e5e;
                    font-weight: 500;
                    background-color: #D2FFE1;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                > :first-child {
                    justify-content: flex-start;
                }
            }
        }

        > .product-entry {
            border-bottom: 1px solid $separator-color;

            > .form-row {
                margin-bottom: 0;
                grid-gap: 0;

                .form-entry {

                    > .input-element {
                        > .bubble {
                            height: 32px;
                            border: none;
                            border-right: 1px solid $separator-color;
                            border-radius: 0;
                        }
                    }
                }
            }

            > .second-row {
                > .form-row {
                    margin: 0;
                    padding: 6px 0;
                    padding-left: 30%;
                    border-top: 1px solid $separator-color;

                    .input-element {
                        height: 27px;
                        display: flex;
                        border-radius: 6px;
                        border: 1px solid #EAECF0;

                        > .name {
                            font-size: 16px;
                            color: #5e5e5e;
                            font-weight: 500;
                            background-color: #D2FFE1;
                            height: 100%;
                            margin: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0 10px;
                            border-right: 1px solid $separator-color;
                        }

                        > .bubble {
                            height: unset;
                            flex: 1;
                            border: none;
                        }
                    }
                }
            }
        }
    }
}

.products-catalog-modal {
    > .modal-content {
        > .modal-body {
            width: 80vw;
            height: 80vh;

            > .table-page {
                width: 100%;
                height: 100%;
            }
        }
        > .modal-footer {
            display: none;
        }
    }
}
