@import "vars";

$row-layout: 30% 200px 100px auto;
$arrow-width: 40px;
$row-height: 20px;

.files {
    padding: 1.07rem 2.14rem 1.07rem 2.86rem;
    min-height: 100vh;

    .file-item {
        background-color: transparent !important;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 5%;
            background-color: rgba(white, 0.81);
            z-index: -1;
        }
    }

    .single-prop {
        [data-component="input"] {
            width: 100%;
        }
    }

    .table-body {
        > .row {
            &:hover {
                background-color: #F3F3F3;
            }
        }
    }

    // > [data-component="table"] {
    //     > .table-header {
    //         > [data-input-type="checkbox"] {
    //             display: none;
    //         }
    //     }

    //     > .table-body {
    //         > .row {
    //             height: $row-height;
    //             overflow: visible;

    //             > [data-input-type="checkbox"] {
    //                 display: none;
    //             }
    //         }
    //     }
    // }

    > .dragging-rows {
        position: fixed;
        height: 100px;
        width: calc(100% - 55px);
        opacity: 61%;
        z-index: 100;
        pointer-events: none;

        > div {
            box-shadow: 0 0 16px 1px rgba(black, .16);
            margin-bottom: -30px;
        }
    }

    .modal-content {
        background-color: #F8F7FC;
    }

    .navigation {
        display: flex;
        justify-content: space-between;

        > .path {
            display: flex;
    
            > .item {
                font-size: 14px;
                cursor: pointer;
    
                &:hover {
                    opacity: .61;
                }
    
                &:before {
                    content: ">";
                    margin: 0 20px;
                }
    
                &:first-child {
                    &:before {
                        content: "";
                        margin: 0;
                    }
                }
    
                &:last-child {
                    font-weight: 600;
                    pointer-events: none;
                }
            }
        }
    }
    
    .file-item {
        height: 57px;
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 11px;
        opacity: 1;
        transition: height .2s;
        transition-property: height, opacity;
        overflow: visible !important;
        padding-left: 18px;

        &[data-is-selected="true"] {
            background-color: #48cd7845 !important;
        }

        &[data-is-booked="true"] {
            background-color: #4891cd45 !important;
        }

        &[data-is-issued="true"] {
            background-color: #bbcd4845 !important;
        }

        &--selected {
            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background-color: rgba($green, .38);
            }
        }

        &--dragging {
            height: 0;
            opacity: 0;
        }

        > .name {
            display: flex;
            align-items: center;
            position: relative;
            flex: 1;

            &[data-is-catalog="true"] {
                &:before {
                    content: url("/assets/icons/catalog.svg");
                    margin-right: 21px;
                    transform: scale(0.7);
                    margin-top: 3px;
                }
            }
        }

        > .counter {
            width: 15px;
            height: 15px;
            background-color: white;
            border: 2px solid black;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 36px;
            bottom: 2px;
            border-radius: 50%;
            font-size: 10px;
            font-weight: 600;
            padding-top: 2px;

            &[data-is-empty="true"] {
                color: #47CE78;
            }
        }

        > .date {
            flex: 1;
        }

        > [role="button"] {
            height: 100%;
            width: 46px;
            padding-right: 10px;
        }
    }

    .modal-body {
        overflow: visible !important;

        > [role="button"] {
            position: absolute;
            right: 0;
            bottom: -58px;
        }
    }

    .img-review {
        object-fit: scale-down;
    }

    .review {
        height: 70vh;
        width: 80vw;
    }

    .loading-file {
        @extend .review;

        > .spinner {
            position: absolute;
            left: calc(50% - 2.86rem);
            top: calc(50% - 2.86rem);
        }
    }

    > .row {
        padding-left: 41px;
        height: $row-height;
        position: relative;
        background-color: white;
        margin-bottom: .5rem;
        align-items: center;

        [role="button"] {
            display: flex;
            justify-content: flex-end;
        }

        &:hover {
            background-color: #F3F3F3;
        }

        .spinner {
            position: absolute;
            left: 5px;
            top: 8px;
            width: fit-content;
            height: fit-content;
        }
    }

    > .upload-bar {
        margin-bottom: 1.1rem;
    }

    > .header {
        display: flex;
        flex-direction: row;
        padding: .85rem 0 1.6rem;
        align-items: center;

        > .search {
            width: 18.9rem;
            display: block;
            margin-right: 1.7rem;

            input {
                width: 100%;
                border-radius: 7px;
                padding: .7rem 1.07rem;
                height: 2.79rem;

                &::placeholder {
                    color: #191717;
                }
            }
        }

        [data-component="button"] {
            margin-right: 2.5rem;

            .icon svg {
                width: 1.4rem;
                height: auto;
            }

            [data-icon-name="filter"] {
                svg {
                    width: 1rem;
                }
            }

            .title {
                letter-spacing: .5px;
                margin-left: .75rem;
            }
        }
    }
    
    .table-header {
        display: grid;
        grid-template-columns: 48% 50%;
        grid-template-rows: 1fr;
        grid-template-areas: "f1 f2 f3 f4 f5";
    }

    &[data-has-extra="true"] {

        .table-header {
            grid-template-columns: 39% 25% 11% 11% 11%;
        }

        .extra {
            width: 11%;
        }
    }
}