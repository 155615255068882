@import "_style-base";

html {
    scroll-behavior: smooth;
    font-size: 11px;

    // @media screen and (min-width: 1280px) {
    //     font-size: 11px;
    // }

    // @media screen and (min-width: 1600px) {
    //     font-size: 12px;
    // }

    // @media screen and (min-width: 1920px) {
    //     font-size: 13px;
    // }

    // @media print {
    //     font-size: 14px;
    // }
}

* {
    box-sizing: border-box;

    @media print {
        box-shadow: none;
    }
}

body {
    margin: 0;
    background: url("/assets/winter-bg.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-color: none;
    font-family: "Poppins", sans-serif;

    @media print {
        background: none;
    }

    &:after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-height: 100vh;
        min-width: 100vw;
        background-color: rgba(white, .85);
        z-index: -1;

        @media print {
            display: none;
        }
    }
}

input, textarea, button, select {
    font-family: inherit;
}

::-webkit-scrollbar {
    width: .86rem;
    height: .86rem;
}

::-webkit-scrollbar-track {
    background: #E8E8E8;
    border-radius: 0;
}

::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: #D2D2D2;
    transition: color .2s ease;
}

::-webkit-scrollbar-thumb:hover {
    background: #BFBEBE;
}

:focus {
    outline: none;
}

#app-body, #app {
    width: 100%;
    min-height: 100vh;
    height: 100vh;
    min-width: 1150px;

    @media print {
        display: none;
    }
}

#app {
    > .page--outer {
        margin-left: 170px;
        height: 100%;
        position: relative;
        transition: margin-left .2s;
        display: flex;
        flex-direction: column;

        > :nth-child(2) {
            overflow-y: scroll;
            overflow-x: hidden;
            
            &::-webkit-scrollbar {
                width: 0px;
                background: transparent;
            }
        }

        > .add-expense {
            height: 100%;
        }

        &[data-is-collapsed="true"] {
            margin-left: 50px;

            @media print {
                margin: 0;
            }
        }
    }
}

[data-is-unavailable="true"] {
    opacity: .5;
    pointer-events: none;
}
