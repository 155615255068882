.table--currencies {
    display: flex;
    padding: 0 0 1.07rem;
    flex-direction: column;
    padding-bottom: 115px;

    [data-component="header"] {
        &:after, &:before {
            visibility: hidden;
        }
    }

    &[data-is-preview="true"] {
        .modal-footer {
            visibility: hidden;
        }

        [data-input-type="date"] {
            pointer-events: none;
        }
    }

    > .page-title {
        font-size: 26px;
        font-weight: 600;
        padding: 2.14rem;
    }

    > .header {
        display: flex;
        flex-direction: row;
        padding: 1.5rem 2.14rem 1rem 2.86rem;

        > .search {
            width: 18.9rem;
            display: block;
            margin-right: 1.7rem;

            input {
                width: 100%;
                border-radius: 7px;
                padding: .7rem 1.07rem;
                height: 2.79rem;

                &::placeholder {
                    color: #191717;
                }
            }
        }

        [data-component="button"] {
            margin-right: 2.2rem;

            .icon svg {
                width: 1.4rem;
                height: auto;
            }

            [data-icon-name="filter"] {
                svg {
                    width: 1rem;
                }
            }

            .title {
                letter-spacing: .5px;
                margin-left: .75rem;
            }
        }
    }

    > [data-component="table"] {
        flex: 1;
        font-size: 1rem;
        margin: 0 2.14rem 0 2.86rem;

        > .table-header {
            display: grid;
            grid-gap: .7rem;
            grid-template-columns: 14.5% 15.5% 12.5% 11% 10% 11% 1fr;
            grid-template-rows: 1fr;
            grid-template-areas: "f1 f1 f2 f2 f3 f4 f5 f6";
        }

        > .table-body > .row {
            margin-bottom: .5rem;
            display: grid;
            grid-gap: .7rem;
            grid-template-columns: 14.5% 15.5% 12.5% 11% 10% 11% 1fr;
            grid-template-rows: 1fr;
            grid-template-areas: "f1 f1 f2 f2 f3 f4 f5 f6";
            min-height: 3.4rem;
            align-items: center;

            [role="button"] {
                justify-content: flex-end;
            }

            > div:first-child {
                padding-left: 2.4rem;
            }
        }
    }

    > .footer {
        position: absolute;
        right: 15px;
        bottom: 15px;
    }

    [data-component="modal"] {
        .modal-content {
            width: 44rem;
        }
    }
}

.table--currencies--add {
    > .top-form {
        padding: 2.68rem 2.14rem 1.8rem;

        > label {
            padding-bottom: 20px;
        }
    }

    > .title {
        width: 100%;
        padding: 5px 2.14rem;
        border-bottom: 1px solid rgba(103, 94, 160, .31);
        font-size: 1.14rem;
        font-weight: 600;
    }

    > .bottom-form {
        padding: 2.68rem 2.14rem 2rem;
        display: grid;
        grid-gap: 1.57rem 1.07rem;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas: "f1 f2 f3 f3" "f4 f5 f6 f7" "f8 f9 . .";

        [data-component="button"] {
            justify-content: start;
        }
    }
}
