.scan-preview {
    height: 100%;

    >.centered {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    img {
        object-fit: contain;
    }

    embed,
    iframe,
    object,
    #tiff-container {
        width: 100%;
        height: 100%;
    }
}