.sale-invoice-number {
    display: flex;
    position: relative;

    > :nth-child(2) {
        > .input-element {
            > .name {
                width: 0;
            }
            > .bubble {
                width: 20px;
            }
        }
    }

    > .icon {
        width: 20px;
        height: 20px;
        border-radius: 6px;
        background-color: #47ce78;
        position: absolute;
        right: 0;
        bottom: 0;
        pointer-events: none;

        > img {
            height: 100%;
            width: 100%;
            padding: 2px;
        }
    }
}
