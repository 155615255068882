@import "vars";

.cookies {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: rgba(white, .95);
    z-index: 1000;
    padding: 30px;
    padding-right: 60px;
    box-shadow: 0 0 16px 1px rgba(black, .16);

    > .policy-button {
        color: $green;
        text-decoration: underline;
        cursor: pointer;
    }

    > .cross {
        position: absolute;
        top: 20px;
        right: 30px;
        font-size: 2rem;
        cursor: pointer;
        transition: opacity .3s;
        opacity: 1;
        font-weight: 300;

        &:hover {
            opacity: .5;
        }
    }
}

.policy-text {
    max-width: 50vw;
}
