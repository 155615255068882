@import "vars";

[data-component="button"] {
    display: inline-flex;
    flex-direction: row;
    padding: .5rem 0;
    font-weight: 600;
    user-select: none;
    align-items: center;
    justify-content: center;
    transition: opacity .3s;
    position: relative;

    &[data-is-loading="true"] {
        pointer-events: none;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: #dbdbdb73;
        }
    }

    > .activity-indicator {
        position: absolute;
        right: 10px;
        z-index: 1;
    }

    &:hover .dropdown, &:focus .dropdown {
        opacity: 1;
        pointer-events: all;
    }

    .dropdown {
        position: absolute;
        top: calc(100% - 10px);
        right: 0;
        background-color: white;
        box-shadow: 0 0 16px 1px rgba(black, .16);
        padding: .5rem 0;
        border-radius: 5px;
        font-weight: normal;
        z-index: 350;
        transition: opacity .3s;
        pointer-events: none;
        opacity: 0;

        &-item {
            padding: .5rem 2rem;
            transition: color .3s, background-color .3s;

            &:hover {
                background-color: $green;
                color: white;
            }
        }
    }

    > .icon {
        display: flex;

        image {
            width: 100%;
            height: 100%;
        }
    }

    > .icon + .title {
        margin-left: 1.1rem;
        font-weight: 600;
        letter-spacing: 1px;
    }

    &:not([data-is-disabled="true"]) {
        cursor: pointer;
    }

    &[data-is-disabled="true"] {
        opacity: .5;
    }

    &.with-border {
        padding: .8rem 1.2rem;
        min-width: 15rem;
        min-height: 3.2rem;
        border-radius: 2rem;
        color: #47CE78;
        border: solid 2px #47CE78;
    }

    &.with-bg {
        padding: .8rem 1.2rem;
        min-width: 15rem;
        min-height: 3.2rem;
        background: linear-gradient(142deg, rgba(71, 206, 120, 1) 0%, rgba(57, 189, 153, 1) 100%);
        border-radius: 2rem;
        color: white;
        background-size: 200% 200%;
        background-image: linear-gradient(142deg, #4DD57E 0%, #38B794 44%, #4DD57E 100%);
        transition: .4s;

        &:hover {
            background-position: right center;
        }

        > .icon {
            position: relative;
            left: -.6rem;
        }

        > .icon + .title {
            margin-left: .5rem;
        }
    }

    &.inactive {
        opacity: .38;
        pointer-events: none;
    }

    &.with-padding {
        padding: .8rem 5rem;
    }

    [data-icon-name="close"] {
        svg {
            width: 1.57rem;
            height: 1.57rem;
        }
    }
}

.btn--filters {
    position: relative;

    [data-component="button"] {

        + .btn-filters__number {
            display: none;
        }
        
        &[data-is-active="true"] {
            + .btn-filters__number {
                color: white;
                position: absolute;
                width: 14px;
                height: 14px;
                font-size: 10px;
                padding: 1px;
                border-radius: 50%;
                background: $green--darker;
                right: 1rem;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: content-box;
            }
        }
    }
}
