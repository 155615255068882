.label-wrapper {
    display: flex;
    align-items: center;
    margin-right: 10px;
    position: relative;

    > .color-picekr-appear {
        position: absolute;
        top: 140px;
        left: 4px;
        z-index: 10;
    }
}

.color-box {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    cursor: pointer;
}

.label-text {
    border: 1px solid transparent;
    padding: 4px 8px;
    min-width: 50px;
    max-width: 200px;
    border-radius: 4px;
    cursor: text;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;

    &[data-is-placeholder="true"] {
        opacity: 0.61;
    }
}

.label-text:focus {
    outline: none;
    border: 1px solid #999;
}
