@import "_vars";

[data-component="table"] [data-component="header"] {
    padding: .7rem 1.9rem .7rem .7rem;
    font-weight: bold;
    position: relative;
    cursor: move;
    user-select: none;
    letter-spacing: .5px;
    display: flex;

    .active-resize {
        right: -2px;
        width: 3px;

        > .resize-tip {
            transform: translateX(-50%);
            opacity: 1;
        }
    }

    > .header-title {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    > .resize-area {
        position: absolute;
        right: -1px;
        width: 1px;
        top: 0;
        bottom: 0;
        cursor: col-resize;
        z-index: 10;
        background-image: linear-gradient(#39BD92, rgba(#39BD92, 0));

        &:hover {
            @extend .active-resize;
        }

        > .resize-tip {
            position: absolute;
            background-color: white;
            padding: 7px 14px;
            top: -36px;
            border-radius: 12px;
            transform: translateX(-50%) translateY(20px);
            opacity: 0;
            transition: .1s opacity;
            transition-property: opacity, transform;
            pointer-events: none;
        }
    }

    &:after, &:before {
        content: "";
        border: solid $green--darker;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        position: absolute;
        right: .8rem;
        opacity: 1;
        transition: opacity .3s;
        cursor: pointer;
    }

    &:after {
        transform: rotate(-135deg);
        top: 30%;
    }

    &:before {
        transform: rotate(45deg);
        top: 48%;
    }

    &[data-value="ASC"]:after {
        opacity: 0;
    }

    &[data-value="DESC"]:before {
        opacity: 0;
    }

    &[data-is-resizing="true"] {
        .resize-area {
            @extend .active-resize;
        }
    }
}
