@import "style-base";

#header {
    height: 41px;
    background-color: white;
    border-bottom: 1px solid rgba(#191717, .08);
    font-weight: 600;
    font-size: 1.86rem;
    padding: 1.3rem 2rem 1rem;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    justify-content: space-between;
    align-items: center;
    letter-spacing: .2rem;

    @media print {
        display: none;
    }

    .navigation-title {
        display: flex;

        > .arrow {
            color: #39BD92;
            padding: 0 10px;
        }

        > .previous {
            @extend .clickable-solid;
            font-weight: normal;
        }
    }

    .header__infos {
        display: flex;
        flex-direction: row;
        font-size: 1rem;
        font-weight: normal;
        text-transform: initial;
        letter-spacing: .1rem;

        &__company {
            display: flex;
            flex-direction: column;
            margin-right: 2rem;

            div:first-child {
                font-weight: 600;
            }

            div {
                max-width: 450px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        [data-component="button"] {
            &.logout svg {
                width: 1.5rem;
                height: auto;
            }

            &.arrow_down {
                margin-right: 1.9rem;

                svg {
                    width: 1rem;
                    height: auto;
                }
            }
        }
    }
}
