.selection-bar {
    padding: 30px;
    position: fixed;
    top: 100px;
    left: calc(50% + 7.75rem);
    transform: translateX(-50%);
    background-color: white;
    box-shadow: 0 0 16px 1px rgba(black, .16);
    border-radius: 20px;
    z-index: 100;
    display: flex;
    align-items: center;

    @keyframes appear-keyframes {
        from {
            opacity: 0;
            transform: translateX(-50%) translateY(-30px);
        }
        to {
            opacity: 1;
            transform: translateX(-50%);
        }
    }

    animation: appear-keyframes .3s;

    > .count {
        font-size: 14px;
        font-weight: 600;
        width: 200px;
    }
    
    > [role="button"] {
        margin-right: 20px;
    }
}
