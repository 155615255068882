.react-time-input-picker-wrapper {
    position: relative;
    display: flex;
    flex-direction: column-reverse;

    > .react-time-input-picker {
        display: flex;

        > .inputWrapper {
            margin-right: 3px;
            > input {
                background-color: white;
                height: 30px;
                border-radius: 7px;
                border: none;
                text-align: end;
            }
        }
    }

    &:after {
        content: "Godzina";
        position: absolute;
        left: 0;
        top: 0;
        color: black;
        font-size: 11px;
        font-weight: 600;
    }
}

.event-details {
    padding: 2.68rem 2.14rem;
    width: 60vw;
}