@import "style-base";


[data-component="header"] {
    background-color: rgba(white, .30);
    width: 100%;
    padding-bottom: 10px;
    white-space: nowrap;
}

.space {
    background-color: transparent !important;
}

.table-header {
    position: relative;
    width: 100%;
    // grid-column-gap: 1px;

    > [data-component="header"] {
        &:nth-last-child(2) {
            > .resize-area {
                display: none;
            }
        }
    }

    > .columns-select {
        @extend .context-appear;
        position: absolute;
        background-color: white;
        box-shadow: 0 0 16px 1px rgba(black, .16);
        border-radius: 6px;
        right: 10px;
        top: 0;
        z-index: 100;

        > .column {
            display: flex;
            padding: 10px;
            padding-left: 45px;
            align-items: center;
            position: relative;

            &:first-child {
                display: none;
            }

            > label {
                transform: translate(34px, 3px);
                opacity: 1;
            }
        }
    }

    &[data-is-settings-disabled="true"] {
        .resize-area {
            pointer-events: none;
        }

        > [data-component="header"] {
            cursor: default;
        }
    }
}

[data-component="table"] {
    position: relative;

    &[data-is-selecting="true"] {
        .checkbox {
            opacity: 1 !important;
            transform: none !important;
        }
    }

    > .table-header {
        &:hover {
            > .checkbox {
                opacity: 1 !important;
                transform: none !important;
            }
        }
    }

    .selection-actions-bar {
        @extend .context-appear;
        position: absolute;
        top: -45px;
        right: 0;
    }

    .selection-checkbox {
        position: absolute;
        left: -22px;
        width: 22px;
        margin: 0;
        padding: 0;
        background-color: transparent;

        opacity: 0;
        transform: translateX(10px);
        transition: opacity .3s;
        transition-property: opacity, transform;

        > .checkbox-input {
            width: 100%;
            position: unset;
            padding-left: 0;
            margin: 0;
            padding: 0;

            > .check {
                position: unset;

                &:after {
                    top: 0.25rem;
                    right: 10px;
                }
            }
        }
    }

    > .table-header {
        .checkbox {
            @extend .selection-checkbox;
            top: 8px;
            
            .check {
                &:after {
                    top: 0.05rem !important;
                }
            }
        }
    }

    > .table-body {
        width: 100%;
        position: relative;

        > .row {
            position: relative;
            margin-bottom: 1px;
            height: 20px;

            &:hover {
                > .checkbox {
                    opacity: 1;
                    transform: none;
                }

                [data-component="input"],
                [data-component="cell"],
                .table-text {
                    opacity: .7;
                }
            }

            > .checkbox {
                @extend .selection-checkbox;
            }

            > div, > label {
                background-color: rgba(white, .81);
                height: 100%;
                white-space: nowrap;
                margin-left: 1px;

                > .input {
                    background-color: transparent;
                }
            }

            > .table-text {
                overflow: hidden;
            }

            > [role="button"] {
                background-color: transparent;
                overflow: visible;
            }

            > div {
                display: flex;
                align-items: center;
            }

            > label {
                justify-content: center;
            }
        }
    }
}


.dragging-element {
    position: absolute;
    pointer-events: none;

    > div, > label {
        background-color: rgba(white, .81);
        height: 20px;
        white-space: nowrap;
        margin-bottom: 1px;
        overflow: hidden;

        &[data-component="header"] {
            background-color: rgba(white, .3);
            height: 33px;
            margin-bottom: 0;
        }
    }
}