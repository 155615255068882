@import "style-base";

.chat {
    width: 50vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    background-color: white;

    > .messages {
        @extend .hideen-scrollbars;
        width: 100%;
        flex: 1;
        overflow-y: scroll;

        > .message {
            width: 100%;
            display: flex;
            margin-bottom: 15px;
            border: 1px solid lightgray;
            border-radius: 5px;

            > .bubble {
                width: 100%;
                padding: 15px;
                border-radius: 15px;

                > .info {
                    display: flex;
                    justify-content: space-between;

                    > .sender {
                        font-size: 16px;
                        font-weight: 600;
                    }

                    > .date {
                        font-size: 14px;
                    }
                }

                > .text-content {
                    font-size: 16px;
                }
            }

            // &[data-sender="support"] {
            //     > .bubble {
            //         color: black;
            //         background-color: #ededed;
            //     }
            // }

            // &[data-sender="me"] {
            //     justify-content: end;

            //     > .bubble {
            //         color: white;
            //         background-color: #47CE78;
            //     }
            // }
        }

        > .loading {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    
    > .message-input {
        height: 140px;
        display: flex;
        flex-direction: column;
        align-items: end;
        
        > textarea {
            flex: 1;
            width: 100%;
            border-radius: 10px;
            margin-bottom: 12px;
            resize: none;
            padding: 12px;
        }

        > [role="button"] {
            width: 100px;
        }
    }
}
