.picked-settlements {
    margin-top: 18px;

    > .picked-settlement-row {
        display: flex;
        gap: 15px;

        > .remove-button {
            @extend .clickable-solid;
            margin-top: 24px;

            img {
                width: 20px;
                height: 20px;
            }
        }
    }
}
