@import "vars";

.upload-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 1px dashed rgba(black, .5);
    border-radius: 2px;

    > .arrow {
        width: 1.86rem;
        height: 1.71rem;
        background: url("/assets/icons/upload_arrow.svg") no-repeat center center;
    }

    > .tip {
        font-size: 1rem;
        font-weight: 600;
        margin-top: .2rem;
        text-align: center;
    }

    > .or {
        font-size: .86rem;
        margin-bottom: .2rem;
        text-align: center;
    }

    > .explorer-button {
        cursor: pointer;
        font-size: .86rem;
        font-weight: 600;
        background-color: $green;
        color: white;
        padding: .36rem 2.14rem;
        border-radius: 14px;
        width: 11.35rem;
        min-width: auto;
        min-height: 1.93rem;
    }
}