.html-content {
    margin: 20px;

    .checkbox-input {
        display: flex;
        align-items: center;
        padding: 5px 0;

        div {
            margin-left: 10px;
            font-size: 13.3px;
        }
    }

    select {
        height: 100%;
        border-radius: 3px;
        border-color: #DDDDDD;
    }
}

[data-multiple-inputs="true"] {
    .checkbox-input {
        
    }
}

.wypelniane {
    // height: 44px;

    &[data-grayed="true"] {
        position: relative;
        
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background-color: lightgray;
        }
    }

    > .cell-content {
        height: 100%;

        &:first-child {
            > .cell-input {
                height: 100%;
            }
        }

        > .cell-input {
            position: relative;
            display: flex;
            width: 100%;
        
            .text-input {
                min-width: 100%;
                border: none;
            }

            > .enum-select {
                width: 100%;
                display: flex;
                align-items: center;
                padding: 3px;

                > div {
                    font-size: 13px;
                    margin-right: 5px;
                    width: 50%;
                }
            }
        }

        &:only-child {
            .enum-select {
                > div {
                    display: none;
                }
            }
        }
    }
}

.date-read {
    height: 26px;
    width: 100%;
    cursor: pointer;
    padding-left: 3px;
    font-size: 13px;

    &:before {
        background-image: url("/assets/icons/calendar.svg");
        background-size: 15px 15px;
        display: inline-block;
        width: 15px; 
        height: 15px;
        content:"";
        margin-right: 5px;
    }
}

.DayPicker {
    position: absolute;
    left: 0;
    top: 3px;
    background-color: white;
    z-index: 100;
    box-shadow: 0 0 26px 1px rgba(black, .16);
}

.enum-cases {
    position: absolute;
    left: 0;
    top: 3px;
    background-color: white;
    z-index: 100;
    box-shadow: 0 0 26px 1px rgba(black, .16);

    width: 100px;
    max-height: 300px;
    overflow: scroll;

    > div {
        position: relative;
        padding: 5px;
        padding-left: 10px;
        
        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background-color: lightgray;
        }

        &:hover {
            color: white;
            background-color: lightskyblue;
        }
    }
}

.oneof-select {
    height: 30px;
}