.kpir-details {
    display: flex;
    overflow: hidden;
    height: calc(100vh - 182px);

    width: 100%;

    &[data-has-preview="true"] {
        width: 100%;
    }

    .currency {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        > .label {
            font-size: 1rem;
            font-weight: 600;
        }

        > .select-box {
            background-color: white;
            border-radius: 7px;
            height: 2.7rem;
            padding-left: 10px;
            padding-right: 16px;

            > select {
                font-size: 1.04rem;
            }
        }
    }
    
    > .file-preview {
        flex: 2;
        
        > div > div > div {
            height: 100%;
            width: 100%;
        }

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    > .input-part {
        flex: 3;
        overflow: scroll;
        overflow-x: hidden;

        > .general {
            padding: 2.68rem 2.14rem;
            display: grid;
            grid-gap: 1.57rem 1.07rem;
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: 1fr 1fr;
            grid-template-areas: "f0 f0 f0 f1 f1 f1" "f2 f2 f2 f3 f3 f3" "f4 f4 f5 f5 f6 f6";
        }

        > .subject {
            position: relative;
            padding: 1.2rem 2.86rem;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: 1fr 1fr 1fr auto;
            gap: .6rem 1.1rem;
            grid-template-areas: "f1 f1 f1 f3 f3 f3" "f6 f6 f7 f7 f8 f8" "f9 f9 f10 f10 f11 f11";

            .gus-button {
                top: auto;
                bottom: 0;
                height: 2.7rem;
                border-radius: 0 7px 7px 0;
            }

            [data-input-type="checkbox"] {
                align-items: flex-end;
            }
        }

        > .values {
            padding: 2.68rem 2.14rem;
            display: grid;
            grid-gap: 1.57rem 13.07rem;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(7, 1fr);;
            grid-template-areas: "f1 f4 f10" "f2 f5 f11" "f3 f7 ." ". f8 ." ". f9  ." "f12 f12 f12" "f13 f13 f13";

            [data-input-type="price"] {
                input {
                    text-align: right;
                }
            }
        }
    }
}
