@import "vars";

.catalog-tree {
    height: 50vh;

    > .item {
        display: flex;
        margin-bottom: 5px;
        cursor: pointer;

        > .arrow {
            width: 20px;
            height: 20px;

            &:hover {
                opacity: .61;
            }

            &[data-has-content] {
                background: url("/assets/icons/tree_arrow.svg") no-repeat center center;
            }

            &[data-is-open="true"] {
                transform: rotate(90deg);
            }
        }

        > .title {
            font-size: 14px;
            font-weight: 600;
            padding: 0 5px;
            
            &:hover {
                opacity: .61;
            }
        }

        &[data-is-selected="true"] {
            > .title {
                color: white;
                background-color: $green;
                pointer-events: none;
            }
        }

        &[data-is-disabled="true"] {
            opacity: .38;
            pointer-events: none;
        }
    }
}
