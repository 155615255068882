.documents {
    .single-modal {
        > .modal-content {
            width: 100vw !important;
            padding-left: 10px;
            padding-right: 10px;

            > .modal-body {
                overflow: hidden;

                .invoice-preview {
                    width: 100%;
                }
            }
        }
    }

    @media print {
        display: none;
    }
}

.statistics {
    font-size: .95rem;
    font-weight: 600;
    margin-top: 1rem;
    width: 41.5%;

    > .stats-row {
        height: 2.8rem;
        align-items: center;
        background: white;
        margin-bottom: .5rem;
        text-align: right;
        display: grid;
        grid-template-columns: 32% 21.6% 18.5% 23%;
        grid-gap: .7rem;
        padding-right: 1rem;

        > :nth-child(1) {
            padding-left: 2.4rem;
            text-align: left;
        }
    }
}

.income--documents {
    display: flex;
    padding: 1.07rem 2.14rem 1.07rem 2.86rem;
    flex-direction: column;
    padding-bottom: 115px;

    > .header {
        display: flex;
        flex-direction: row;
        padding: .85rem 0 1.6rem;
        align-items: center;

        > .search {
            width: 18.9rem;
            display: block;
            margin-right: 1.7rem;

            input {
                width: 100%;
                border-radius: 7px;
                padding: .7rem 1.07rem;
                height: 2.79rem;

                &::placeholder {
                    color: #191717;
                }
            }
        }

        [data-component="button"] {
            margin-right: 2.5rem;

            .icon svg {
                width: 1.4rem;
                height: auto;
            }

            [data-icon-name="filter"] {
                svg {
                    width: 1rem;
                }
            }

            .title {
                letter-spacing: .5px;
                margin-left: .75rem;
            }
        }
    }

    > .footer {
        position: absolute;
        right: 3.3rem;
        bottom: 0;
    }

    &--add {
        padding: 2.68rem 2.14rem;
    }

    [data-component="modal"] {
        > .modal-content {
            padding: 0 10px;

            > .modal-body {
                > .sale-invoice-details {
                    height: auto;
                }
            }

            > .modal-header {
                padding: 10px 10px 0;
                top: 10px;

                .cross {
                    top: 1rem;
                    right: 1rem;
                }
            }

            > .modal-footer {
                display: none;
            }
        }
    }
}
