.single-label-creator {
    $creator-color: #39bd92;

    width: fit-content;
    position: relative;

    > .control-element {
        position: relative;

        > .creator-button {
            background-color: $creator-color;
            color: white;
            display: flex;
            align-items: center;
            justify-content: start;
            padding-left: 7px;
            width: fit-content;
            min-width: 22px;
            height: 22px;
            border-radius: 11px;
            transition: min-width 300ms ease-out;
        }

        > .label-placeholder {
            position: absolute;
            color: white;
            top: 3px;
            left: 7px;
            opacity: 0.61;
            pointer-events: none;
        }
    }

    > .icon-button {
        @extend .clickable-solid;
        position: absolute;
        top: -1px;
        right: -32px;
        width: 26px;
        height: 26px;

        &:nth-child(4) {
            right: -61px;
        }

        > img {
            width: 100%;
            height: 100%;
        }
    }

    > .spinner {
        position: absolute;
        right: -27px;
        top: 5px;
    }

    .color-picekr-appear {
        @extend .context-appear;
        position: absolute;
        bottom: -133px;
        right: 133px;

        > .color-picker {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            box-shadow: 0 0 16px 1px rgba(black, .3);
            background-color: white;
            transform: translateY(-100%);
    
            > .hue-tile {
                @extend .clickable-solid;
                width: 20px;
                height: 20px;
                margin: 1px;

                &[data-is-picked="true"] {
                    margin: 3px;
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    &[data-is-loading="true"] {
        pointer-events: none;

        > .control-element {
            > .creator-button {
                opacity: 0.61;
            }
        }

        > .color-picekr-appear {
            opacity: 0.61;
        }

        > .icon-button {
            display: none;
        }
    }

    &[data-is-creating="true"] {
        > .control-element {
            > .creator-button {
                padding-right: 7px;
                min-width: 63px;
            }
        }
    }

    &[data-is-creating="false"] {
        > .icon-button {
            display: none;
        }

        > .control-element {
            @extend .clickable-solid;   
        }
    }
}
