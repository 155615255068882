.cost-invoice-subject-section {
    .nip {
        display: flex;
        align-items: end;

        > .form-entry {
            flex: 1;
        }

        .form-entry >.input-element >.bubble {
            border-radius: 6px 0 0 6px;
        }

        > .button {
            height: 20px;
            min-height: 20px;
            transform: translateX(-6px);
        }
    }

    > .form-row {
        > .form-row {
            margin-bottom: 0;
            column-gap: 8px;
        }
    }
}
