.kst-details {
    // background-color: lightgray;

    .kst-list {
        padding: 2.68rem 2.14rem;
    }

    .kst-section {
        padding: 2.68rem 2.14rem;
        display: grid;
        grid-gap: 1.57rem 1.07rem;
        grid-template-columns: repeat(5, 1fr);
    }

    .general-section {
        @extend .kst-section;
        grid-template-rows: repeat(5, 1fr);
        grid-template-areas: "f1 f1 f2 f2 f3 f3" "f4 f4 f5 f5 f6 f6" "f7 f7 f8 f8 f9 f9" "f10 f10 f11 f11 f12 f12" "f13 f13 f14 f14 f15 f15" "f16 f16 f17 f17 f17 f17";

        [data-input-type="checkbox"] {
            > .title {
                height: auto;
                text-overflow: clip;
                white-space: normal;
            }
        }
    }

    .amort-section {
        @extend .kst-section;
        grid-template-rows: repeat(4, 1fr);
        grid-template-areas: "f1 f1 f2 f2 f3 f3" "f4 f4 f5 f5 f6 f6" "f7 f7 f8 f8 f9 f9" "f10 f10 f11 f11 f12 f12" "f13 . . . . .";
    }
}