.placeholder {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: lightgray;
    font-weight: 600;
    font-size: 17px;
}
