@keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.spinner {
    display: flex;
    animation: rotating 1.21s linear infinite;
}
