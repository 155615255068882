@import "style-base";

.desktop-settings {
    @extend .hideen-scrollbars;
    padding-left: 2.86rem;

    > .title {
        font-size: 1.86rem;
        font-weight: 600;
        margin: 4rem 0 3rem;
    }

    > .tiles {
        display: grid;
        grid-template-columns: repeat(9, 80px);
        grid-auto-rows: 80px;
        grid-gap: 28px;
        background-color: white;
        width: fit-content;
        padding: 50px;

        > .tile {
            position: relative;

            > .overlay {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(#5078F0, .1);
                transition: transform .1s;
            }

            > img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            &:hover {
                > .overlay {
                    transform: scale(1.2);
                }
            }
        }
    }
}
